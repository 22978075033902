var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row mt-4" }, [
    _c(
      "div",
      { staticClass: "col-12 mx-auto align-middle" },
      [
        _c("GolferSelector", {
          attrs: { selectedGolfer: _vm.filters.user_id, golfers: _vm.golfers },
          on: {
            updateGolfer: function ($event) {
              return _vm.onGetGolfer($event)
            },
          },
        }),
        _vm.filters.user_id && _vm.roundReviews
          ? _c("RoundsSummaryTable", {
              attrs: {
                roundReviews: _vm.roundReviews,
                userid: _vm.filters.user_id,
              },
              on: {
                onNavTo: function ($event) {
                  return _vm.onGetNavTo($event)
                },
              },
            })
          : _vm._e(),
        _vm.filters.user_id
          ? _c(
              "RoundsTable",
              {
                attrs: {
                  filters: _vm.filters,
                  roundsInfo: _vm.roundsInfo,
                  title: _vm.getRoundsTableTitle(),
                  ifShowFilter: _vm.ifShowFilter,
                },
                on: {
                  updateFilters: function ($event) {
                    return _vm.onGetFilters($event)
                  },
                },
              },
              [
                _vm.ifShowFilter
                  ? _c("DetailFilter", {
                      attrs: { filters: _vm.filters },
                      on: {
                        updateFilter: function ($event) {
                          return _vm.onGetFilters($event)
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }