var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-card",
        [
          _c(
            "b-row",
            { staticClass: "text-center" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c("div", { staticClass: "h3" }, [
                    _vm._v(_vm._s(_vm.outing.course) + " - "),
                    _c("small", [
                      _vm._v(
                        _vm._s(_vm._f("momentformat")(_vm.outing.date, "MM/DD"))
                      ),
                    ]),
                  ]),
                  _c("div", [
                    _vm.outing.tgroup_id && _vm.outing.tgroup_id != 0
                      ? _c("span", [
                          _vm._v(
                            "Group " + _vm._s(_vm.outing.tgroup_id) + " - "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" " + _vm._s(_vm.outing.numplayers) + " players "),
                  ]),
                  _c("div", [
                    _vm.outing.player_names
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.outing.player_names) + " "),
                        ])
                      : _vm._e(),
                  ]),
                  _c(
                    "b-col",
                    { staticClass: "mb-2", attrs: { cols: "12" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: _vm.outing.accesscode
                              ? "success"
                              : "secondary",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.goTo()
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.outing.accesscode
                                  ? "Score outing"
                                  : "View outing"
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _vm.outing.accesscode
                        ? _c(
                            "b-button",
                            {
                              attrs: { variant: "danger" },
                              on: { click: _vm.showModal },
                            },
                            [_vm._v(" Cancel ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: "Confirm Cancelation",
            "ok-title": "Confirm",
            "cancel-title": "Cancel",
          },
          on: {
            ok: function ($event) {
              return _vm.cancel(_vm.outing.id)
            },
          },
          model: {
            value: _vm.isModalVisible,
            callback: function ($$v) {
              _vm.isModalVisible = $$v
            },
            expression: "isModalVisible",
          },
        },
        [_vm._v(" Are you sure you want to cancel this outing? ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }