var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-4 mb-4" },
    [
      _c("RoundForm", {
        attrs: {
          isEdit: _vm.isEdit,
          round: _vm.round,
          regions: _vm.regions,
          teeboxes: _vm.teeboxes,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }