var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { overflow: "auto" } }, [
    _c(
      "table",
      { staticClass: "text-center table-lines" },
      [
        _c("tr", [
          _c("th", [_vm._v("Player")]),
          _c("th", [_vm._v("Thru")]),
          _c("th", [_vm._v("Gross")]),
          _c("th", [_vm._v("Net")]),
          +_vm.contest_id == 17 || +_vm.contest_id == 18
            ? _c("th", [
                _vm.gameRabbit
                  ? _c("img", {
                      staticStyle: {
                        height: "2em",
                        width: "auto",
                        "object-fit": "contain",
                      },
                      attrs: { src: "/img/rabbit_free.PNG" },
                    })
                  : _c("span", [_vm._v("Rabbit")]),
              ])
            : _vm._e(),
          +_vm.contest_id == 14 ? _c("th", [_vm._v("Sixes")]) : _vm._e(),
          +_vm.contest_id == 16 ? _c("th", [_vm._v("Stableford")]) : _vm._e(),
          +_vm.contest_id == 16 ? _c("th", [_vm._v("Pace")]) : _vm._e(),
        ]),
        _vm._l(_vm.orderedScores, function (s) {
          return _c("tr", { key: s.key }, [
            _c("td", [
              _vm._v(
                " " + _vm._s(s.last) + _vm._s(_vm._f("initial")(s.first)) + " "
              ),
              +_vm.contest_id == 17 &&
              s.rabbit &&
              s.rabbit.nine &&
              s.rabbit.nine > 0
                ? _c("img", {
                    staticStyle: {
                      height: "0.8em",
                      width: "auto",
                      "object-fit": "contain",
                    },
                    attrs: { src: "/img/rabbit_captured.PNG" },
                  })
                : _vm._e(),
            ]),
            _c("td", [_vm._v(_vm._s(s.thru))]),
            _c("td", [_vm._v(_vm._s(_vm._f("withE")(s.gross)))]),
            _c("td", [_vm._v(_vm._s(_vm._f("withE")(s.net)))]),
            +_vm.contest_id == 17 || +_vm.contest_id == 18
              ? _c("td", [
                  s.rabbit && s.rabbit.rabbit && s.rabbit.rabbit > 0
                    ? _c("img", {
                        staticStyle: {
                          height: "2em",
                          width: "auto",
                          "object-fit": "contain",
                        },
                        attrs: { src: "/img/rabbit_captured.PNG" },
                      })
                    : _vm._e(),
                ])
              : _vm._e(),
            +_vm.contest_id == 14
              ? _c("td", [_vm._v(_vm._s(s.sixes))])
              : _vm._e(),
            +_vm.contest_id == 16
              ? _c("td", [
                  _vm._v(_vm._s(s.stableford ? s.stableford.points : "n/a")),
                ])
              : _vm._e(),
            +_vm.contest_id == 16
              ? _c("td", [
                  _vm._v(_vm._s(s.stableford ? s.stableford.pace : "n/a")),
                ])
              : _vm._e(),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }