var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { on: { click: _vm.printCard } }, [_vm._t("default")], 2),
      _c(
        "b-modal",
        {
          attrs: { "ok-only": "" },
          model: {
            value: _vm.showCard,
            callback: function ($$v) {
              _vm.showCard = $$v
            },
            expression: "showCard",
          },
        },
        [
          _c("img", {
            staticStyle: { width: "100%", height: "auto" },
            attrs: { src: _vm.cardSrc },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }