var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card col-12 col-md-5 mx-auto full-height" },
    [
      _c("div", { staticClass: "card-body" }, [
        _c("h5", { staticClass: "card-title text-center" }, [
          _vm._v("Your Email is :"),
        ]),
        _c("p", { staticClass: "card-text text-center" }, [
          _vm._v(" " + _vm._s(_vm.user.email)),
        ]),
        _c("br"),
        _c("div", { staticClass: "text-center" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary btnWidth",
              on: {
                click: function ($event) {
                  return _vm.toGeneratePwd()
                },
              },
            },
            [_vm._v("Send Password")]
          ),
        ]),
      ]),
      _c("hr"),
      _c("div", { staticClass: "card-body" }, [
        _c(
          "form",
          {
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.handleSubmit()
              },
            },
          },
          [
            _c("h5", { staticClass: "card-title" }, [_vm._v("New Email:")]),
            _c("p", { staticClass: "card-text" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.email,
                    expression: "email",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  type: "email",
                  id: "email",
                  placeholder: "Email Addr",
                },
                domProps: { value: _vm.email },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.email = $event.target.value
                  },
                },
              }),
            ]),
            _c("br"),
            _vm._m(0),
          ]
        ),
        _c("br"),
        _vm.errors.length
          ? _c(
              "p",
              { staticClass: "alert alert-danger", attrs: { role: "alert" } },
              [
                _c("b", [_vm._v("Please correct the following error(s):")]),
                _c(
                  "ul",
                  _vm._l(_vm.errors, function (error, index) {
                    return _c("li", { key: index }, [_vm._v(_vm._s(error))])
                  }),
                  0
                ),
              ]
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center" }, [
      _c(
        "button",
        { staticClass: "btn btn-primary btnWidth", attrs: { type: "submit" } },
        [_vm._v("Change Email Addr and Send Password")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }