var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-12 mx-auto align-middle" }, [
      _c("div", { staticClass: "card mb-1 table-title" }, [
        _c("div", { staticClass: "card-header text-center" }, [
          _c("i", {
            staticClass: "fa fa-arrow-circle-left back-icon",
            on: {
              click: function ($event) {
                return _vm.$router.go(-1)
              },
            },
          }),
          _c("b", [_vm._v("Final Step : Round Review")]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "col-12 p-3" }, [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-header" }, [
          _vm._v(" Round Information "),
        ]),
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-4 col-md-2" }, [_vm._v(" Course: ")]),
            _c("div", { staticClass: "col-8 col-md-2 text-right" }, [
              _c("b", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.round && _vm.round.course && _vm.round.course.name
                        ? _vm.round.course.name
                        : ""
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("div", { staticClass: "col-4 col-md-2" }, [_vm._v(" Region: ")]),
            _c("div", { staticClass: "col-8 col-md-2 text-right" }, [
              _c("b", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.selectedRegion && _vm.selectedRegion.region
                        ? _vm.selectedRegion.region
                        : ""
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-4 col-md-2" }, [_vm._v(" Date: ")]),
            _c("div", { staticClass: "col-8 col-md-2 text-right" }, [
              _c("b", [
                _vm._v(
                  " " +
                    _vm._s(_vm.round && _vm.round.date ? _vm.round.date : "") +
                    " "
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm.round
      ? _c("div", { staticClass: "col-12 p-3" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-header" }, [
              _vm._v(
                " " +
                  _vm._s(
                    (_vm.user && _vm.user.firstname ? _vm.user.firstname : "") +
                      " " +
                      (_vm.user && _vm.user.lastname ? _vm.user.lastname : "")
                  ) +
                  " "
              ),
            ]),
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-4 col-md-2" }, [
                  _vm._v(" Adj Score: "),
                ]),
                _c("div", { staticClass: "col-8 col-md-2 text-right" }, [
                  _c("b", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.round && _vm.round.score ? _vm.round.score : ""
                        ) +
                        " " +
                        _vm._s(
                          _vm.round &&
                            _vm.round.tournament &&
                            _vm.round.tournament == 1
                            ? "T"
                            : ""
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "col-4 col-md-2" }, [
                  _vm._v(" Tee: "),
                ]),
                _vm.selectedTeebox !== undefined
                  ? _c("div", { staticClass: "col-8 col-md-2 text-right" }, [
                      _c("b", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.selectedTeebox.description
                                ? _vm.selectedTeebox.description
                                : ""
                            ) +
                            " "
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "col-4 col-md-2" }, [
                  _vm._v(" Holes Par or Better: "),
                ]),
                _c("div", { staticClass: "col-8 col-md-2 text-right" }, [
                  _c("b", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.round && _vm.round.parorbetter
                            ? _vm.round.parorbetter
                            : ""
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "col-4 col-md-2" }, [
                  _vm._v(" Alcohol: "),
                ]),
                _c("div", { staticClass: "col-8 col-md-2 text-right" }, [
                  _c("b", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.round && _vm.round.alcohol
                            ? _vm.getAlcoholConsumption(_vm.round.alcohol)
                            : "None"
                        ) +
                        " "
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm.round && _vm.round.golfers && _vm.round.golfers.length
      ? _c(
          "div",
          { staticClass: "col-12 p-3" },
          [
            _vm._m(0),
            _vm._l(_vm.round.golfers, function (user, index) {
              return _c("div", { key: index, staticClass: "card" }, [
                _c("div", { staticClass: "card-header" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        (user.firstname && user.firstname
                          ? user.firstname
                          : "") +
                          " " +
                          (user.lastname && user.lastname ? user.lastname : "")
                      ) +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "card-body" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-4 col-md-2" }, [
                      _vm._v(" Adj Score: "),
                    ]),
                    _c("div", { staticClass: "col-8 col-md-2 text-right" }, [
                      _c("b", [
                        _vm._v(
                          " " +
                            _vm._s(user && user.score ? user.score : "") +
                            " " +
                            _vm._s(
                              user && user.tournament && user.tournament == 1
                                ? "T"
                                : ""
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-4 col-md-2" }, [
                      _vm._v(" Tee: "),
                    ]),
                    _c("div", { staticClass: "col-8 col-md-2 text-right" }, [
                      _c("b", [
                        _vm._v(
                          " " + _vm._s(user ? _vm.getTeebox(user) : "") + " "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-4 col-md-2" }, [
                      _vm._v(" Holes Par or Better: "),
                    ]),
                    _c("div", { staticClass: "col-8 col-md-2 text-right" }, [
                      _c("b", [
                        _vm._v(
                          " " +
                            _vm._s(
                              user && user.parorbetter ? user.parorbetter : ""
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-4 col-md-2" }, [
                      _vm._v(" Alcohol: "),
                    ]),
                    _c("div", { staticClass: "col-8 col-md-2 text-right" }, [
                      _c("b", [
                        _vm._v(
                          " " +
                            _vm._s(
                              user && user.alcohol
                                ? _vm.getAlcoholConsumption(user.alcohol)
                                : "None"
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ])
            }),
          ],
          2
        )
      : _vm._e(),
    _c("div", { staticClass: "col-12 p-3" }, [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-header" }, [
          _vm._v("Course Conditions"),
        ]),
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-4 col-md-2" }, [_vm._v("Date:")]),
            _c("div", { staticClass: "col-8 col-md-2 text-right" }, [
              _c("b", [
                _vm._v(
                  " " +
                    _vm._s(_vm.round && _vm.round.date ? _vm.round.date : "") +
                    " "
                ),
              ]),
            ]),
          ]),
          _vm.round &&
          _vm.round.course &&
          _vm.round.course.coursecom &&
          _vm.round.course.coursecom != ""
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-4 col-md-2" }, [
                  _vm._v("Comment:"),
                ]),
                _c("div", { staticClass: "col-8 col-md-2 text-right" }, [
                  _c("b", [
                    _vm._v(" " + _vm._s(_vm.round.course.coursecom) + " "),
                  ]),
                ]),
              ])
            : _vm._e(),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-4 col-md-2" }, [_vm._v("Fairways:")]),
            _c("div", { staticClass: "col-8 col-md-2 text-right" }, [
              _c("b", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.round &&
                        _vm.round.course &&
                        _vm.round.course.fairwaycondition
                        ? _vm.getOptionName(
                            "fairwayConditions",
                            _vm.round.course.fairwaycondition
                          )
                        : ""
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-4 col-md-2" }, [_vm._v("Greens:")]),
            _c("div", { staticClass: "col-8 col-md-2 text-right" }, [
              _c("b", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.round &&
                        _vm.round.course &&
                        _vm.round.course.greenscondition
                        ? _vm.getOptionName(
                            "greenConditions",
                            _vm.round.course.greenscondition
                          )
                        : ""
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-4 col-md-2" }, [
              _vm._v("Green Speed:"),
            ]),
            _c("div", { staticClass: "col-8 col-md-2 text-right" }, [
              _c("b", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.round &&
                        _vm.round.course &&
                        _vm.round.course.greensspeed
                        ? _vm.getOptionName(
                            "greenSpeeds",
                            _vm.round.course.greensspeed
                          )
                        : ""
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-4 col-md-2" }, [_vm._v("Value:")]),
            _c("div", { staticClass: "col-8 col-md-2 text-right" }, [
              _c("b", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.round && _vm.round.course && _vm.round.course.value
                        ? _vm.getOptionName(
                            "valueRatings",
                            _vm.round.course.value
                          )
                        : ""
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "form-group col-12" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-6" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              staticStyle: { width: "100%" },
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.onSubmitForm()
                },
              },
            },
            [_vm._v("Save Scores")]
          ),
        ]),
        _c("div", { staticClass: "col-6" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-info",
              staticStyle: { width: "100%" },
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.cancelPostRound()
                },
              },
            },
            [_vm._v("Cancel")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card-header" }, [
        _vm._v(" Other Golfers Information "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }