var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "button",
        {
          staticClass: "btn btn-info edit-col",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.editRound()
            },
          },
        },
        [_vm._v("Edit")]
      ),
      _c(
        "b-modal",
        {
          attrs: { "ok-title": "Save", size: "xl", title: "Edit Round" },
          on: {
            ok: _vm.saveRound,
            shown: _vm.init,
            hidden: function ($event) {
              _vm.loaded = false
            },
          },
          model: {
            value: _vm.openModal,
            callback: function ($$v) {
              _vm.openModal = $$v
            },
            expression: "openModal",
          },
        },
        [
          _vm.loaded
            ? _c(
                "div",
                [
                  _c("RoundForm", {
                    attrs: {
                      isEdit: _vm.isEdit,
                      isModal: _vm.isModal,
                      round: _vm.round,
                      regions: _vm.regions,
                      teeboxes: _vm.teeboxes,
                    },
                  }),
                ],
                1
              )
            : _c(
                "div",
                { staticStyle: { "text-align": "center" } },
                [
                  _c("b-spinner", {
                    staticStyle: { width: "3rem", height: "3rem" },
                    attrs: { variant: "success", label: "Loading" },
                  }),
                ],
                1
              ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }