import { render, staticRenderFns } from "./RoundForm.html?vue&type=template&id=07bf9ed6&scoped=true&external"
import script from "./RoundForm.vue?vue&type=script&lang=js"
export * from "./RoundForm.vue?vue&type=script&lang=js"
import style0 from "./RoundForm.css?vue&type=style&index=0&id=07bf9ed6&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07bf9ed6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/websites/desert-nomads/app.desertnomads.org/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('07bf9ed6')) {
      api.createRecord('07bf9ed6', component.options)
    } else {
      api.reload('07bf9ed6', component.options)
    }
    module.hot.accept("./RoundForm.html?vue&type=template&id=07bf9ed6&scoped=true&external", function () {
      api.rerender('07bf9ed6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/roundForm/RoundForm.vue"
export default component.exports