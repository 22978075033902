var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("CourseRoundsTable", {
        attrs: { filters: _vm.filters, roundInfos: _vm.roundInfos },
        on: {
          updateFilters: function ($event) {
            return _vm.onGetFilters($event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }