var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.roster, function (group, gidx) {
      return _c("div", { key: gidx, staticClass: "card mt-3" }, [
        _c("div", { staticClass: "card-header" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-12 col-md-auto" }, [
              _c("h5", [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: group.name != null,
                        expression: "group.name != null",
                      },
                    ],
                  },
                  [_vm._v("Group " + _vm._s(group.name))]
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: group.name == null,
                        expression: "group.name == null",
                      },
                    ],
                  },
                  [_vm._v("No Group Assigned")]
                ),
              ]),
            ]),
            _c("div", { staticClass: "col-sm-12 col-md-auto" }, [
              _c("h5", [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: group.name != null,
                        expression: "group.name != null",
                      },
                    ],
                  },
                  [
                    _vm._v(
                      "Starting Hole: " +
                        _vm._s(group.startinghole) +
                        " @ " +
                        _vm._s(group.teetime)
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "card-body m-0 p-0 py-2 table-responsive" }, [
          _c(
            "table",
            {
              staticClass:
                "card-body table table-bordered table-striped table-sm",
            },
            [
              _c(
                "colgroup",
                [
                  _c("col", { attrs: { width: "40%" } }),
                  _c("col"),
                  _c("col"),
                  _c("col"),
                  _c("col"),
                  _vm._l(_vm.teeboxes, function (teebox, i) {
                    return _c("col", { key: i })
                  }),
                ],
                2
              ),
              _c("thead", [
                _c(
                  "tr",
                  [
                    _c("th", { attrs: { align: "left" } }, [_vm._v("Name")]),
                    _c("th", { attrs: { align: "left" } }, [
                      _vm._v("Opponent/Partner"),
                    ]),
                    _c("th", { attrs: { align: "right" } }, [_vm._v("Index")]),
                    _c("th", { attrs: { align: "right" } }, [
                      _vm._v("Strokes"),
                    ]),
                    _c("th", { attrs: { align: "left" } }, [_vm._v("Flight")]),
                    _vm._l(_vm.teeboxes, function (teebox, i) {
                      return _c("th", { key: i }, [
                        _vm._v(_vm._s(teebox.description) + " "),
                      ])
                    }),
                  ],
                  2
                ),
              ]),
              _c(
                "tbody",
                _vm._l(group.players, function (player, pidx) {
                  return _c(
                    "tr",
                    { key: pidx },
                    [
                      _c("td", [_vm._v(_vm._s(player.name))]),
                      _c("td", [_vm._v(_vm._s(player.partner))]),
                      _c("td", { attrs: { align: "right" } }, [
                        _vm._v(_vm._s(player.handicapindex)),
                      ]),
                      _c("td", { attrs: { align: "right" } }, [
                        _vm._v(_vm._s(player.coursehandicap)),
                      ]),
                      _c("td", [_vm._v(_vm._s(player.flight))]),
                      _vm._l(player.teeboxes, function (teebox, i) {
                        return _c("td", { key: i }, [
                          _vm._v(" " + _vm._s(teebox.calculated) + " "),
                        ])
                      }),
                    ],
                    2
                  )
                }),
                0
              ),
            ]
          ),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }