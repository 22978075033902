var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-12 mx-auto align-middle" },
      [
        _c(
          "CourseTable",
          {
            attrs: { filters: _vm.filters, courseInfo: _vm.courseInfo },
            on: {
              updateFilters: function ($event) {
                return _vm.onGetFilters($event)
              },
            },
          },
          [
            _c("CourseFilter", {
              attrs: { filters: _vm.filters },
              on: {
                updateFilter: function ($event) {
                  return _vm.onGetFilters($event)
                },
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }