var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container mt-4 mb-4" },
    [
      _c("div", { staticClass: "row justify-content-md-center" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "col-12 col-md-3 mb-2" },
          [
            _c(
              "router-link",
              {
                staticClass: "btn btn-primary nav-btn",
                attrs: { to: `/postRound` },
              },
              [_vm._v("Post Round")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-12 col-md-3 mb-2" },
          [
            _c(
              "router-link",
              {
                staticClass: "btn btn-primary nav-btn",
                attrs: { to: `/recentPostings` },
              },
              [_vm._v("Recent Postings")]
            ),
          ],
          1
        ),
        _vm.isAdmin
          ? _c(
              "div",
              { staticClass: "col-12 col-md-3 mb-2" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn-primary nav-btn",
                    attrs: { to: `/addTeetime` },
                  },
                  [_vm._v("Add Tee Time")]
                ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "col-12 col-md-3 mb-2" },
          [
            _c(
              "router-link",
              {
                staticClass: "btn btn-primary nav-btn",
                attrs: { to: `/tournament` },
              },
              [_vm._v("Score Round")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-12 col-md-3 mb-2" },
          [
            _c(
              "router-link",
              {
                staticClass: "btn btn-primary nav-btn",
                attrs: { to: `/sendFeeler` },
              },
              [_vm._v("Send Feeler")]
            ),
          ],
          1
        ),
      ]),
      _vm.feelers && _vm.feelers.length ? _c("hr") : _vm._e(),
      _c("FeelerCard", { attrs: { feelers: _vm.feelers } }),
      _vm.teetime && _vm.teetime.length ? _c("hr") : _vm._e(),
      _c("TeetimeCard", { attrs: { teetime: _vm.teetime, user: _vm.user } }),
      _vm.hasOutings ? _c("hr") : _vm._e(),
      _vm.hasOutings
        ? _c("h4", { staticClass: "text-center mt-3 mb-4" }, [
            _vm._v("Current Outings"),
          ])
        : _vm._e(),
      _vm.myoutings && _vm.myoutings.length
        ? _c(
            "b-row",
            _vm._l(_vm.myoutings, function (o) {
              return _c(
                "b-col",
                {
                  key: o.id,
                  staticClass: "mt-4",
                  attrs: { cols: "12", md: "6", lg: "4" },
                },
                [
                  _c("OutingCard", {
                    attrs: { outing: o },
                    on: { outingCancelled: _vm.handleOutingCancelled },
                  }),
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _vm.otheroutings && _vm.otheroutings.length
        ? _c(
            "b-row",
            _vm._l(_vm.otheroutings, function (o) {
              return _c(
                "b-col",
                {
                  key: o.id,
                  staticClass: "mt-4",
                  attrs: { cols: "12", md: "6", lg: "4" },
                },
                [
                  _c("OutingCard", {
                    attrs: { outing: o, flagUser: _vm.user_id },
                  }),
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c("br"),
      _c("p", [
        _vm._v(
          'We are now under 2020 World Handicap System, Equitable Score Control (ECS) has been replaced with a "net double-bogey" rule. Click '
        ),
        _c(
          "a",
          {
            attrs: {
              href: "https://www.usga.org/content/usga/home-page/handicapping/world-handicap-system/World-Handicap-System-5-Things-You-Need-to-Know.html",
            },
          },
          [_vm._v(" here")]
        ),
        _vm._v(" to understand how this change affects you."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }