var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("notifications", { attrs: { group: "errors" } }),
      _c("notifications", { attrs: { group: "notices" } }),
      _c("loader-overlay"),
      _c(_vm.layout, { tag: "component" }, [_c("router-view")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }