var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "card col-12 col-md-5 mx-auto text-center" },
      [_c("h4", [_vm._v("Redirecting")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }