var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-form-group",
        { attrs: { label: "Select Time Period" } },
        [
          _c(
            "b-form-select",
            {
              on: { change: _vm.updateChart },
              model: {
                value: _vm.selectedPeriod,
                callback: function ($$v) {
                  _vm.selectedPeriod = $$v
                },
                expression: "selectedPeriod",
              },
            },
            [
              _c("b-form-select-option", { attrs: { value: 1 } }, [
                _vm._v("1 Year"),
              ]),
              _c("b-form-select-option", { attrs: { value: 3 } }, [
                _vm._v("3 Years"),
              ]),
              _c("b-form-select-option", { attrs: { value: 5 } }, [
                _vm._v("5 Years"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("b-button", { on: { click: _vm.updateHandicapType } }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.isAntiHandicap ? "Show Handicap" : "Show Anti-Handicap"
            ) +
            " "
        ),
      ]),
      _c("line-chart", {
        ref: "lineChart",
        attrs: { "chart-data": _vm.chartData, options: _vm.chartOptions },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }