var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card col-12 col-md-5 mx-auto" }, [
    _c(
      "div",
      { staticClass: "card-body text-center" },
      [
        _vm._m(0),
        _c(
          "router-link",
          { staticClass: "btn btn-primary", attrs: { to: `login` } },
          [_vm._v("Try Login")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "card-text" }, [
      _c("b", [
        _vm._v("New password has been sent. You can login in now from here."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }