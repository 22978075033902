var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "text-right mb-4" },
        [
          _c(
            "b-button",
            {
              on: {
                click: function ($event) {
                  return _vm.leave()
                },
              },
            },
            [_vm._v("Logout")]
          ),
        ],
        1
      ),
      _c("Scoring", {
        attrs: {
          code: _vm.code,
          group: _vm.group,
          wolf_scores: _vm.scores.wolf,
          new_wolf: _vm.scores.new_wolf,
        },
        on: { update: _vm.getScoreBoard },
      }),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _vm.scores && +_vm.scores.contest_id == 14
            ? _c("Sixes", { attrs: { scores: _vm.scores.sixes } })
            : _vm._e(),
          _c("div", { staticClass: "mt-4" }),
          _vm.scores &&
          _vm.scores.scores &&
          _vm.scores.scores.length > 0 &&
          _vm.scores.contest_id != 21
            ? _c("IndividualStroke", {
                attrs: {
                  scores: _vm.scores.scores,
                  contest_id: _vm.scores.contest_id,
                },
              })
            : _vm._e(),
          _vm.scores && +_vm.scores.contest_id == 21
            ? _c("Wolf", {
                attrs: {
                  scores: _vm.scores.scores,
                  wolf_scores: _vm.scores.wolf,
                },
              })
            : _vm._e(),
          _vm.scores.grossskins && _vm.scores.grossskins.length > 0
            ? _c(
                "div",
                { staticClass: "text-center mt-4" },
                [
                  _c("h3", [_vm._v("Gross Skins")]),
                  _c("Skins", { attrs: { scores: _vm.scores.grossskins } }),
                ],
                1
              )
            : _vm._e(),
          _vm.scores.netskins && _vm.scores.netskins.length > 0
            ? _c(
                "div",
                { staticClass: "text-center mt-4" },
                [
                  _c("h3", [_vm._v("Net Skins")]),
                  _c("Skins", { attrs: { scores: _vm.scores.netskins } }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }