var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AimCenterModal", {
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c(
              "h5",
              { staticClass: "modal-title", attrs: { id: "modalLongTitle" } },
              [_vm._v("Do you want to select course? ")]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _vm._v(" Name : "),
            _vm.course && _vm.course.name
              ? _c("span", [_vm._v(_vm._s(_vm.course.name))])
              : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                attrs: { type: "button", "data-dismiss": "modal" },
              },
              [_vm._v("No")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button", "data-dismiss": "modal" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.confirm()
                  },
                },
              },
              [_vm._v("Yes")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }