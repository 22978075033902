var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "card-header text-center" }, [
      _vm._v(" " + _vm._s(_vm.title) + " "),
    ]),
    _c("div", { staticClass: "card-body" }, [
      _vm._v(
        " Players List - " +
          _vm._s(_vm.golferNum) +
          " signed up - " +
          _vm._s(
            Math.abs(_vm.availableSpots) +
              " " +
              (_vm.availableSpots >= 0 ? "spots open" : "on waitlist")
          ) +
          " "
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }