var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.loading > 0,
          expression: "loading > 0",
        },
      ],
      staticClass: "loader-container",
    },
    [_vm._m(0)]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "loader-content" }, [
      _c("img", { attrs: { src: "/img/loading-trans.gif" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }