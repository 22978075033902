var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card col-12 col-md-5 mx-auto" }, [
    _c("div", { staticClass: "card-body" }, [
      _c("h4", { staticClass: "card-title" }, [_vm._v("Reset Your Password")]),
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.handleSubmit()
            },
          },
        },
        [
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "username" } }, [
              _vm._v("Enter Username"),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.userForm.username,
                  expression: "userForm.username",
                },
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                id: "username",
                placeholder: "username",
                required: "",
              },
              domProps: { value: _vm.userForm.username },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.userForm, "username", $event.target.value)
                },
              },
            }),
          ]),
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "password" } }, [
              _vm._v("Enter Current Password"),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.userForm.password,
                  expression: "userForm.password",
                },
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                id: "password",
                placeholder: "password",
                required: "",
              },
              domProps: { value: _vm.userForm.password },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.userForm, "password", $event.target.value)
                },
              },
            }),
          ]),
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "username" } }, [
              _vm._v("Enter new Password"),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.userForm.newpassword,
                  expression: "userForm.newpassword",
                },
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                id: "newpassword",
                placeholder: "New Password",
                required: "",
              },
              domProps: { value: _vm.userForm.newpassword },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.userForm, "newpassword", $event.target.value)
                },
              },
            }),
          ]),
          _c("br"),
          _c(
            "button",
            {
              staticClass: "btn btn-primary float-right",
              attrs: { type: "submit" },
            },
            [_vm._v("Continue")]
          ),
        ]
      ),
      _vm.errors.length
        ? _c("p", [
            _c("b", [_vm._v("Please correct the following error(s):")]),
            _c(
              "ul",
              _vm._l(_vm.errors, function (error, index) {
                return _c("li", { key: index }, [_vm._v(_vm._s(error))])
              }),
              0
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }