var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { overflow: "auto" } }, [
    _c(
      "table",
      { staticClass: "text-center table-lines" },
      [
        _vm._m(0),
        _vm._l(_vm.scores, function (s) {
          return _c("tr", { key: s.key }, [
            _c("td", [
              _vm._v(
                " " +
                  _vm._s(s.last) +
                  " " +
                  _vm._s(_vm._f("initial")(s.first)) +
                  " "
              ),
            ]),
            _c("td", [_vm._v(_vm._s(s.thru))]),
            _c("td", [_vm._v(_vm._s(_vm._f("withE")(s.gross)))]),
            _c("td", [_vm._v(_vm._s(_vm._f("withE")(s.net)))]),
            _c("td", [_vm._v(_vm._s(_vm._f("noScore")(s.wscore)))]),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", [_vm._v("Player")]),
      _c("th", [_vm._v("Thru")]),
      _c("th", [_vm._v("Gross")]),
      _c("th", [_vm._v("Net")]),
      _c("th", [_vm._v("Wolf Points")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }