var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _vm.teetime && _vm.teetime.length
      ? _c("div", { staticClass: "col-12 mx-auto" }, [
          _c("h4", { staticClass: "text-center mt-3 mb-4" }, [
            _vm._v("Scheduled Outings / Tournaments and Tee Times"),
          ]),
          _c(
            "div",
            { staticClass: "d-lg-none" },
            _vm._l(_vm.teetime, function (time, i) {
              return _c("div", { key: i, staticClass: "card mb-3" }, [
                _c("div", { staticClass: "card-header" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12 text-right" }, [
                      _c(
                        "b",
                        { staticClass: "badge badge-success badge-pill" },
                        [_vm._v(" $ " + _vm._s(time.rate))]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _vm._m(0, true),
                    _c("div", { staticClass: "col-8 text-right pl-0" }, [
                      _c(
                        "span",
                        { staticClass: "badge badge-primary badge-pill" },
                        [_vm._v(_vm._s(time.num_wanted) + " total")]
                      ),
                      _vm._v("  "),
                      _c(
                        "span",
                        { staticClass: "badge badge-dark badge-pill" },
                        [_vm._v(" " + _vm._s(time.playercount) + " taken")]
                      ),
                      _vm._v("  "),
                      _c(
                        "span",
                        { staticClass: "badge badge-success badge-pill" },
                        [
                          _vm._v(
                            _vm._s(Math.abs(time.spotsleft)) +
                              " " +
                              _vm._s(
                                time.spotsleft < 0 ? "On Waitlist" : "Open"
                              )
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col spread-out" }, [
                      _c("span", [
                        _c("b", [_vm._v(" Date : ")]),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("momentformat")(time.date, "MM/DD dddd")
                            )
                        ),
                      ]),
                      _c("span", [
                        _c("b", [_vm._v("Time : ")]),
                        _vm._v(
                          " " + _vm._s(_vm._f("removeseconds")(time.time))
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "card-body" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("div", { staticClass: "row" }, [
                        _vm._m(1, true),
                        _c("div", { staticClass: "col-9 pl-0 text-right" }, [
                          time.website
                            ? _c(
                                "a",
                                {
                                  attrs: {
                                    href: time.website,
                                    target: "_blank",
                                  },
                                },
                                [_vm._v(_vm._s(time.name))]
                              )
                            : _c("span", [_vm._v(_vm._s(time.name))]),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("div", { staticClass: "row" }, [
                        _vm._m(2, true),
                        _c("div", { staticClass: "col pl-0" }, [
                          _c("p", [_vm._v(_vm._s(time.contact_info))]),
                        ]),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col text-center" },
                          [
                            time.TeeID != ""
                              ? _c(
                                  "router-link",
                                  {
                                    staticClass: "btn btn-warning mt-1",
                                    attrs: {
                                      to: `teetime/${time.teeid}/tourney/players`,
                                    },
                                  },
                                  [_vm._v("Players")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                  time.tournament == 1
                    ? _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-12 text-center" },
                          [
                            _c("hr"),
                            time.signedup == 1
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "text-success",
                                    staticStyle: { "font-weight": "bold" },
                                  },
                                  [_vm._v("SIGNUP CONFIRMED")]
                                )
                              : _c(
                                  "router-link",
                                  {
                                    staticClass: "btn btn-danger",
                                    attrs: {
                                      to: `teetime/${time.teeid}/tourney/signup`,
                                    },
                                  },
                                  [_vm._v(" Sign Up Now! ")]
                                ),
                            _c("br"),
                            time.prepay == 1
                              ? _c("span", [
                                  _c("br"),
                                  _vm._v(
                                    " Already signed up and just need to pay?"
                                  ),
                                  _c("br"),
                                  _c("br"),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href:
                                          "https://paypal.me/nomads/" +
                                          time.rate,
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: "https://www.paypalobjects.com/en_US/i/btn/btn_paynow_SM.gif",
                                        },
                                      }),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "card-footer" }, [
                  _c("div", { staticClass: "row" }, [
                    _vm._m(3, true),
                    _c("div", { staticClass: "col-6 pl-0 text-right" }, [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(time.firstname) +
                            " " +
                            _vm._s(time.lastname)
                        ),
                      ]),
                      _c("br"),
                    ]),
                    _c("div", { staticClass: "col-12 text-right" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: `mailto:${time.email} ?subject=Join Outing`,
                          },
                        },
                        [_vm._v(_vm._s(time.email))]
                      ),
                    ]),
                    _c("div", { staticClass: "col-12 text-right" }, [
                      time.email != "outings@desertnomads.org"
                        ? _c("span", [_vm._v(_vm._s(time.phonenum))])
                        : _vm._e(),
                    ]),
                  ]),
                  _vm.user.isadmin == 1 ||
                  _vm.user.id == time.member_submitted_id
                    ? _c("hr")
                    : _vm._e(),
                  _vm.user.isadmin == 1 ||
                  _vm.user.id == time.member_submitted_id
                    ? _c(
                        "div",
                        { staticClass: "row text-center" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "btn btn-primary",
                              staticStyle: { width: "100%" },
                              attrs: { to: `editTeetime/${time.teeid}` },
                            },
                            [_vm._v(" Edit ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ])
            }),
            0
          ),
          _c("div", { staticClass: "d-none d-lg-flex" }, [
            _c("div", { staticClass: "table-responsive" }, [
              _c(
                "table",
                { staticClass: "table table-striped table-bordered" },
                [
                  _vm._m(4),
                  _c(
                    "tbody",
                    _vm._l(_vm.teetime, function (time, i) {
                      return _c("tr", { key: i, staticClass: "text-center" }, [
                        _vm._m(5, true),
                        _c(
                          "td",
                          {
                            staticClass: "align-middle",
                            attrs: { NOWRAP: "" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("momentformat")(
                                    time.date,
                                    "MM/DD dddd"
                                  )
                                )
                            ),
                            _c("br"),
                            _vm._v(
                              " " + _vm._s(_vm._f("removeseconds")(time.time))
                            ),
                            _c("br"),
                            _vm._v(" " + _vm._s(time.name)),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(time.num_wanted) +
                                " total : " +
                                _vm._s(time.playercount) +
                                " taken : " +
                                _vm._s(Math.abs(time.spotsleft)) +
                                " " +
                                _vm._s(
                                  time.spotsleft < 0 ? "On Waitlist" : "Open"
                                )
                            ),
                            _c("br"),
                            _vm._v(" $" + _vm._s(time.rate) + " "),
                          ]
                        ),
                        _c(
                          "td",
                          {
                            staticClass: "align-middle",
                            attrs: { NOWRAP: "" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(time.firstname) +
                                " " +
                                _vm._s(time.lastname)
                            ),
                            _c("br"),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: `mailto:${time.email} ?subject=Join Outing`,
                                },
                              },
                              [_vm._v(_vm._s(time.email))]
                            ),
                            _vm._v("  "),
                            _c("br"),
                            time.email != "outings@desertnomads.org"
                              ? _c("span", [_vm._v(_vm._s(time.phonenum))])
                              : _vm._e(),
                            time.email == "outings@desertnomads.org"
                              ? _c("br")
                              : _vm._e(),
                          ]
                        ),
                        _c("td", { staticClass: "align-middle" }, [
                          _vm._v(" " + _vm._s(time.contact_info) + " "),
                        ]),
                        _c("td", { staticClass: "align-middle action-col" }, [
                          time.tournament == 1
                            ? _c(
                                "span",
                                [
                                  time.signedup == 1
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "text-success",
                                          staticStyle: {
                                            "font-weight": "bold",
                                          },
                                        },
                                        [_vm._v("SIGNUP CONFIRMED")]
                                      )
                                    : _c(
                                        "router-link",
                                        {
                                          staticClass: "btn btn-danger",
                                          attrs: {
                                            to: `teetime/${time.teeid}/tourney/signup`,
                                          },
                                        },
                                        [_vm._v(" Sign Up Now! ")]
                                      ),
                                  _c("br"),
                                  time.prepay == 1
                                    ? _c("span", [
                                        _c("br"),
                                        _vm._v(
                                          " Already signed up and just need to pay?"
                                        ),
                                        _c("br"),
                                        _c("br"),
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href:
                                                "https://paypal.me/nomads/" +
                                                time.rate,
                                              target: "_blank",
                                            },
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: "https://www.paypalobjects.com/en_US/i/btn/btn_paynow_SM.gif",
                                              },
                                            }),
                                          ]
                                        ),
                                        _c("br"),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                        _c(
                          "td",
                          { staticClass: "align-middle action-col" },
                          [
                            _c(
                              "b-button-group",
                              [
                                _vm.user.isadmin == 1 ||
                                _vm.user.id == time.member_submitted_id
                                  ? _c(
                                      "router-link",
                                      {
                                        staticClass: "btn btn-primary",
                                        attrs: {
                                          to: `editTeetime/${time.teeid}`,
                                        },
                                      },
                                      [_vm._v("Edit")]
                                    )
                                  : _vm._e(),
                                time.TeeID != ""
                                  ? _c(
                                      "router-link",
                                      {
                                        staticClass: "btn btn-warning",
                                        attrs: {
                                          to: `teetime/${time.teeid}/tourney/players`,
                                        },
                                      },
                                      [_vm._v("Players")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ])
                    }),
                    0
                  ),
                ]
              ),
            ]),
          ]),
        ])
      : _vm._e(),
    !_vm.teetime || _vm.teetime.length < 1
      ? _c("h4", { staticClass: "col-12 mx-auto title" }, [
          _vm._v("NO EVENTS CURRENTLY SCHEDULED "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-4 pr-0" }, [
      _c("b", [_vm._v("Spots : ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-3" }, [_c("b", [_vm._v("Course : ")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-auto" }, [
      _c("b", [_vm._v("Details : ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-6" }, [
      _c("b", [_vm._v("Submitted By : ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "text-center", attrs: { colspan: "2" } }, [
          _vm._v(" Details "),
        ]),
        _c("th", { staticClass: "text-center" }, [_vm._v(" Submitted By ")]),
        _c("th", { staticClass: "text-center" }, [_vm._v(" Details ")]),
        _c("th"),
        _c("th"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "text-right align-middle" }, [
      _c("i", [_vm._v("Date:")]),
      _c("br"),
      _c("i", [_vm._v("Time:")]),
      _c("br"),
      _c("i", [_vm._v("Course:")]),
      _c("br"),
      _c("i", [_vm._v("Spots:")]),
      _c("br"),
      _c("i", [_vm._v("Rate:")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }