var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.step == 1
        ? _c("CheckRobot", {
            on: {
              sendUsername: function ($event) {
                return _vm.onGetUsername($event)
              },
            },
          })
        : _vm.step == 2 && _vm.user && _vm.user.email
        ? _c("ChooseResetType", {
            attrs: { user: _vm.user },
            on: {
              sendNewEmail: function ($event) {
                return _vm.onGetNewEmail($event)
              },
              sendPwd: function ($event) {
                return _vm.toSendPwd($event)
              },
            },
          })
        : _vm.step == 3
        ? _c("FinishReset")
        : _vm._e(),
      _vm.errors && _vm.errors.length
        ? _c("Error", {
            staticClass: "col-12 col-md-5 mx-auto",
            attrs: { errors: _vm.errors },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }