var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "h-100", staticStyle: { width: "100%" } }, [
    _vm.teeboxes
      ? _c("div", { staticClass: "card table-title border-bottom-0" }, [
          _vm._m(0),
        ])
      : _vm._e(),
    _c(
      "div",
      {
        staticClass:
          "table-responsive mb-0 table-striped border-top-0 table-bordered",
      },
      [
        _c("table", { staticClass: "table mb-0 table-striped" }, [
          _vm._m(1),
          _vm.sortedTeeboxes && _vm.sortedTeeboxes.length > 0
            ? _c(
                "tbody",
                _vm._l(_vm.sortedTeeboxes, function (teebox) {
                  return _c("tr", { key: teebox.id }, [
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(teebox.description)),
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(teebox.rating)),
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(teebox.slope)),
                    ]),
                  ])
                }),
                0
              )
            : _c("tbody", [_vm._m(2)]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header text-center" }, [
      _c("b", [_vm._v("Teeboxes")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "text-center align-middle" }, [
          _vm._v("Teebox"),
        ]),
        _c("th", { staticClass: "text-center align-middle" }, [
          _vm._v("Rating "),
        ]),
        _c("th", { staticClass: "text-center align-middle" }, [
          _vm._v("Slope "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-text-center", attrs: { colspan: "3" } }, [
        _c("b", [_vm._v("No Teebox Info")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }