var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row mb-md-3" }, [
      _c(
        "div",
        { staticClass: "col-12 offset-md-1 col-md-6 mb-2 mb-md-0" },
        [
          _c("CourseGeneralInfoCard", {
            staticClass: "h-100",
            attrs: { course: _vm.course, courseRecord: _vm.courseRecord },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-12 col-md-4 mb-2 mb-md-0" },
        [
          _c("TeeboxTable", {
            staticClass: "h-100",
            attrs: { teeboxes: _vm.teeboxes },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row mb-md-3" }, [
      _c(
        "div",
        { staticClass: "col-12 mb-2 mb-md-0" },
        [
          _c("CourseConditionsCard", {
            staticClass: "h-100",
            attrs: { course: _vm.course },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row mb-md-3" }, [
      _c(
        "div",
        { staticClass: "col-12 col-md-6 offset-md-3" },
        [
          _c("AimSelector", {
            attrs: {
              selectors: _vm.selectors,
              selectedValue: _vm.defaultSelectorValue,
            },
            on: {
              updateSelect: function ($event) {
                return _vm.onGetSelect($event)
              },
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12 col-md-10 offset-md-1" },
        [
          _c("router-view", {
            attrs: { course: _vm.course, teeboxes: _vm.teeboxes },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }