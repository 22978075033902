var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "btn btn-primary",
      attrs: { type: "button" },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.onDelete()
        },
      },
    },
    [
      _c("span", [_vm._v(" " + _vm._s(_vm.finalInfoString) + " ")]),
      !_vm.noDelete
        ? _c("i", { staticClass: "fas fa-times-circle" })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }