var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { overflow: "auto" } }, [
    _c(
      "table",
      { staticClass: "text-center table-lines" },
      [
        _vm._m(0),
        _vm._l(_vm.scores, function (s) {
          return _c(
            "tr",
            { key: s.key, class: { active: s.individual == 1 } },
            [
              _c("td", [_vm._v(_vm._s(s.hole))]),
              _c("td", [_vm._v(_vm._s(s.par))]),
              _c("td", [_vm._v(_vm._s(s.handicap))]),
              _c("td", [_vm._v(_vm._s(s.score))]),
              _c("td", [_vm._v(_vm._s(s.name))]),
            ]
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", [_vm._v("Hole")]),
      _c("th", [_vm._v("Par")]),
      _c("th", [_vm._v("Handicap")]),
      _c("th", [_vm._v("Score")]),
      _c("th", [_vm._v("Golfer(s)")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }