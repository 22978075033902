var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("nav-bar"),
      _c(
        "div",
        { staticClass: "container mt-4 mb-4" },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-8" }, [_vm._t("default")], 2),
            _c("div", { staticClass: "col-4" }, [_c("side-bar")], 1),
          ]),
          _vm._t("default"),
        ],
        2
      ),
      _c("footer-bar"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }