var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-12 mx-auto align-middle" },
      [
        _c("GolferList", {
          attrs: {
            filters: _vm.filters,
            usersInfo: _vm.usersInfo,
            title: "Step 3 : Select Golfer Then Set Score",
            "exclude-self": "",
          },
          on: {
            updateFilters: function ($event) {
              return _vm.onGetFilters($event)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "selectedGolfers",
              fn: function () {
                return [
                  _vm.selectedUsers && _vm.selectedUsers.length
                    ? _c("div", { staticClass: "card-header" }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c("b", [_vm._v("Selected Golfers  ")]),
                            _c("b", [
                              _vm._v(
                                "(" + _vm._s(_vm.selectedUsers.length) + ")"
                              ),
                            ]),
                          ]),
                        ]),
                        _c("hr"),
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            [
                              _vm._l(_vm.selectedUsers, function (user, index) {
                                return _c("AimBadge", {
                                  key: index,
                                  staticClass: "mr-2 mb-1",
                                  attrs: {
                                    infos: [user.firstname, user.lastname],
                                  },
                                  on: {
                                    updateDelete: function ($event) {
                                      return _vm.onGetDelete($event, user)
                                    },
                                  },
                                })
                              }),
                              _vm.selectedUsers && _vm.selectedUsers[0]
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-info float-right",
                                      on: {
                                        click: function ($event) {
                                          return _vm.goToNextStep()
                                        },
                                      },
                                    },
                                    [_vm._v("Set Golfers' Scores")]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
            {
              key: "filter",
              fn: function () {
                return [
                  _c("GolferFilter", {
                    attrs: { filters: _vm.filters },
                    on: {
                      updateFilter: function ($event) {
                        return _vm.onGetFilters($event)
                      },
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }