var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("TeetimeForm", {
    attrs: {
      teetime: _vm.teetime,
      isEdit: _vm.isEdit,
      courses: _vm.courses,
      contests: _vm.contests,
      user: _vm.user,
      teeboxes: _vm.teeboxes,
    },
    on: {
      updateTeetime: function ($event) {
        return _vm.onUpdateTeetime($event)
      },
      onSearchTeeboxes: function ($event) {
        return _vm.onGetSearchTeeboxesFilter($event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }