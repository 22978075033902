var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row mt-4" }, [
    _c("div", { staticClass: "col-12 mx-auto align-middle" }, [
      !_vm.submitted
        ? _c(
            "form",
            {
              staticClass: "form",
              attrs: { name: "signupForm" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.register.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "card mb-3 text-center" }, [
                _c("div", { staticClass: "row card-body" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("h2", [
                      _vm._v(
                        _vm._s(
                          _vm._f("momentformat")(
                            _vm.tournament.date,
                            "MM/DD dddd"
                          )
                        ) +
                          " " +
                          _vm._s(_vm._f("removeseconds")(_vm.tournament.time))
                      ),
                    ]),
                    _c("h3", [_vm._v(_vm._s(_vm.tournament.contactinfo))]),
                    _c("p", [
                      _vm._v(
                        "We are always striving to make the tournaments as fair and competitive as possible. Please assist us with this goal by taking a minute to fill out the following form to the best of your knowledge. Thank You!"
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "card mb-3" }, [
                _c("div", { staticClass: "card-body" }, [
                  _vm.admin && _vm.selectedGolferId && _vm.golfers
                    ? _c("div", { staticClass: "row form-group" }, [
                        _c(
                          "label",
                          {
                            staticClass: "control-label col-sm-3 text-sm-right",
                            attrs: { for: "name" },
                          },
                          [_vm._v("Golfer:")]
                        ),
                        _c(
                          "div",
                          { staticClass: "col col-md-3" },
                          [
                            _c("GolferSelector", {
                              attrs: {
                                selectedGolfer: _vm.selectedGolferId,
                                golfers: _vm.golfers,
                              },
                              on: {
                                updateGolfer: function ($event) {
                                  return _vm.getGolferInfo($event)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "row form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "control-label col-sm-3 text-sm-right",
                        attrs: { for: "name" },
                      },
                      [_vm._v("Name:")]
                    ),
                    _c("div", { staticClass: "col" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.signup.name,
                            expression: "signup.name",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", name: "name", required: "" },
                        domProps: { value: _vm.signup.name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.signup, "name", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "row form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "control-label col-sm-3 text-sm-right",
                        attrs: { for: "email" },
                      },
                      [_vm._v("Email:")]
                    ),
                    _c("div", { staticClass: "col w-100" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.signup.email,
                            expression: "signup.email",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "email", name: "email", required: "" },
                        domProps: { value: _vm.signup.email },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.signup, "email", $event.target.value)
                          },
                        },
                      }),
                      _c("span", { staticClass: "ml-3" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.signup.notify,
                              expression: "signup.notify",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.signup.notify)
                              ? _vm._i(_vm.signup.notify, null) > -1
                              : _vm.signup.notify,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.signup.notify,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.signup,
                                      "notify",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.signup,
                                      "notify",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.signup, "notify", $$c)
                              }
                            },
                          },
                        }),
                        _vm._v(" Send Email Notification? "),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "row form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "control-label col-sm-3 text-sm-right",
                        attrs: { for: "phone" },
                      },
                      [_vm._v("Phone:")]
                    ),
                    _c("div", { staticClass: "col" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.signup.phone,
                            expression: "signup.phone",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", name: "phone" },
                        domProps: { value: _vm.signup.phone },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.signup, "phone", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "row form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "control-label col-sm-3 text-sm-right",
                        attrs: { for: "handicap" },
                      },
                      [_vm._v("USGA Index or Estimated Handicap:")]
                    ),
                    _c("div", { staticClass: "col" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.signup.handicap,
                            expression: "signup.handicap",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", name: "handicap", required: "" },
                        domProps: { value: _vm.signup.handicap },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.signup,
                              "handicap",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "row form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "control-label col-sm-3 text-sm-right",
                        attrs: { for: "membershipid" },
                      },
                      [_vm._v("GHIN (if known):")]
                    ),
                    _c("div", { staticClass: "col" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.signup.membership_id,
                            expression: "signup.membership_id",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", name: "membershipid" },
                        domProps: { value: _vm.signup.membership_id },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.signup,
                              "membership_id",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "row form-group w-100" }, [
                    _c("div", { staticClass: "col text-center" }, [
                      _c("strong", [
                        _vm._v(
                          "Tournament Fee: $" + _vm._s(_vm.tournament.rate)
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "card mb-3" }, [
                _vm._m(0),
                _c(
                  "table",
                  {
                    staticClass:
                      "card-body table table-sm table-bordered table-striped w-auto mx-auto",
                  },
                  [
                    _vm._m(1),
                    _c(
                      "tbody",
                      _vm._l(_vm.signup.lastrounds, function (round, ridx) {
                        return _c("tr", { key: ridx }, [
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: round.course,
                                  expression: "round.course",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text" },
                              domProps: { value: round.course },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(round, "course", $event.target.value)
                                },
                              },
                            }),
                          ]),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: round.score,
                                  expression: "round.score",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text", size: "3" },
                              domProps: { value: round.score },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(round, "score", $event.target.value)
                                },
                              },
                            }),
                          ]),
                        ])
                      }),
                      0
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "card mb-3" }, [
                _c("div", { staticClass: "card-body row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("p", { staticClass: "text-center" }, [
                      _vm._v("What was your best score ever?"),
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _vm._m(2),
                      _c("div", { staticClass: "col" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.signup.course_best,
                              expression: "signup.course_best",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: { value: _vm.signup.course_best },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.signup,
                                "course_best",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _vm._m(3),
                      _c("div", { staticClass: "col" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.signup.score_best,
                              expression: "signup.score_best",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: { value: _vm.signup.score_best },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.signup,
                                "score_best",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "card mb-3" }, [
                _c("div", { staticClass: "card-body row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("p", { staticClass: "text-center" }, [
                      _vm._v(
                        "If you crushed one off the tee under normal conditions, how many yards would it go?"
                      ),
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _vm._m(4),
                      _c("div", { staticClass: "col" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.signup.crunch,
                              expression: "signup.crunch",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: { value: _vm.signup.crunch },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.signup,
                                "crunch",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "card mb-3" }, [
                _c("div", { staticClass: "card-body row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("p", { staticClass: "text-center" }, [
                      _vm._v(
                        "Please provide the names and/or email addresses of your intended playing partners or any special requests you may have:"
                      ),
                    ]),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.signup.foursome,
                          expression: "signup.foursome",
                        },
                      ],
                      staticClass: "form-control",
                      domProps: { value: _vm.signup.foursome },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.signup, "foursome", $event.target.value)
                        },
                      },
                    }),
                    _c("p", { staticClass: "text-danger text-center" }, [
                      _vm._v(
                        "Although you may have listed your partners, please register any partner or each golfer in your group separately."
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _vm._m(5),
            ]
          )
        : _vm._e(),
      _vm.submitted
        ? _c("div", [
            _c("div", { staticClass: "card mb-3" }, [
              _vm._m(6),
              _c("div", { staticClass: "card-body row px-0" }, [
                _c("div", { staticClass: "col text-center" }, [
                  _c("p", [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.tournament.tournamenttitle)),
                    ]),
                  ]),
                  _c("p", [
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm._f("momentformat")(
                            _vm.tournament.date,
                            "MM/DD dddd"
                          )
                        )
                      ),
                    ]),
                    _vm._v(" at "),
                    _c("strong", [
                      _vm._v(
                        _vm._s(_vm._f("removeseconds")(_vm.tournament.time))
                      ),
                    ]),
                  ]),
                  _c("p", [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.signup.notify && _vm.signup.email,
                            expression: "signup.notify && signup.email",
                          },
                        ],
                      },
                      [
                        _vm._v("A confirmation email has been sent to "),
                        _c("b", [_vm._v(_vm._s(_vm.signup.email))]),
                        _vm._v(". "),
                      ]
                    ),
                    _vm._v(
                      " No further confirmation is necessary and you are expected to show at least twenty minutes prior to the starting time. If we are not using a shotgun start and if you are in a later starting time, we will make every effort possible to contact you as a courtesy. If you have to cancel, please contact as soon as possible. No shows can negatively affect future fees and participation."
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.tournament.prepay,
                          expression: "tournament.prepay",
                        },
                      ],
                    },
                    [
                      _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.tournament.deadline == "0000-00-00",
                              expression: "tournament.deadline == '0000-00-00'",
                            },
                          ],
                        },
                        [
                          _vm._v(
                            "You're welcome to take care of greens fees at the course or you can prepay in one of the following ways:"
                          ),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.tournament.deadline != "0000-00-00",
                              expression: "tournament.deadline != '0000-00-00'",
                            },
                          ],
                        },
                        [
                          _vm._v(
                            "This event has a payment deadline of " +
                              _vm._s(_vm.tournament.deadline) +
                              ". If payment is not received by " +
                              _vm._s(_vm.tournament.deadline) +
                              ", your entry will be cancelled."
                          ),
                        ]
                      ),
                      _c("ul", { staticClass: "mx-0 text-left" }, [
                        _vm._m(7),
                        _c("li", { staticClass: "mb-3" }, [
                          _vm._v("Paypal (or credit card) to "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "https://www.paypal.me/nomads/" +
                                  _vm.tournament.rate,
                              },
                            },
                            [_vm._v("paypal.me/nomads")]
                          ),
                        ]),
                        _c("li", [
                          _vm._v(
                            "You can use Venmo as well, Sending money to: @Mark-Slovensky-1"
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._m(8),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("h4", { staticClass: "text-center" }, [_vm._v("Last Five Rounds")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [_c("th", [_vm._v("Course")]), _c("th", [_vm._v("Score")])]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "control-label col-4 col-lg-2 text-right" },
      [_c("i", [_vm._v("Course:")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "control-label col-4 col-lg-2 text-right" },
      [_c("i", [_vm._v("Score:")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "control-label col-4 col-lg-2 text-right" },
      [_c("i", [_vm._v("Score:")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col text-center" }, [
        _c(
          "button",
          { staticClass: "btn btn-primary", attrs: { type: "submit" } },
          [_vm._v("Sign Up")]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("h4", { staticClass: "text-center" }, [_vm._v("Signup Confirmation")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "mb-3" }, [
      _vm._v("Check or money order made payable to:"),
      _c("br"),
      _c("br"),
      _vm._v(" Desert Nomads Golf Club"),
      _c("br"),
      _vm._v(" c/o AIM IT Services"),
      _c("br"),
      _vm._v(" 4720 E COTTON GIN LOOP STE 135"),
      _c("br"),
      _vm._v(" Phoenix, AZ 85040 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "Thank you for your participation. If you have any questions, please email:"
      ),
      _c("br"),
      _c("a", { attrs: { href: "mailto:outings@desertnomads.org" } }, [
        _vm._v("outings@desertnomads.org"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }