var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-inline" },
    [
      _c(
        "a",
        {
          attrs: { href: "javascript:void(0)" },
          on: {
            click: function ($event) {
              _vm.showModal = true
            },
          },
        },
        [
          _vm._t("default", function () {
            return [_vm._v("Game Info")]
          }),
        ],
        2
      ),
      _c(
        "b-modal",
        {
          attrs: { size: "xl", "hide-footer": "", scrollable: "" },
          model: {
            value: _vm.showModal,
            callback: function ($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal",
          },
        },
        [
          +_vm.contest == 14
            ? _c("div", [
                _c("b", [_vm._v("Sixes")]),
                _c("br"),
                _c("span", [
                  _vm._v(
                    " A fourball game where 2 on 2 teams change partners every 6 holes for 3 matches of match play. In the event a match finishes early, those extra holes will be added onto the next match. The application will randomly pick partners for you. "
                  ),
                ]),
              ])
            : _vm._e(),
          +_vm.contest == 16
            ? _c("div", [
                _c("b", [_vm._v("Stableford")]),
                _c("br"),
                _c("p", [_vm._v("2 or more players")]),
                _c("p", [
                  _vm._v(
                    "You receive a number of points awarded on each hole based on your net strokes to par."
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "Your goal to to score the most points based on scoring system below. 36 points would indicate you shot a net par."
                  ),
                ]),
                _c("ul", [
                  _c("li", [_vm._v("6 points – Four strokes under")]),
                  _c("li", [_vm._v("5 points – Three strokes under")]),
                  _c("li", [_vm._v("4 points – Two strokes under")]),
                  _c("li", [_vm._v("3 points – One stroke under")]),
                  _c("li", [_vm._v("2 points – Level par")]),
                  _c("li", [
                    _vm._v("1 point"),
                    _c("div", {
                      staticStyle: { display: "inline-block", width: "7px" },
                    }),
                    _vm._v(" – One Stroke over"),
                  ]),
                  _c("li", [_vm._v("0 points – Two strokes or more over")]),
                ]),
              ])
            : _vm._e(),
          +_vm.contest == 17 || +_vm.contest == 18
            ? _c("div", [
                _c("b", [_vm._v("Rabbit")]),
                _c("br"),
                _c("p", [
                  _vm._v(
                    " Hunt the rabbit. Only one player can catch it at a time or it gets loose."
                  ),
                  _c("br"),
                  _vm._v(
                    " Set your $prize between each other as to what the bounty is to be payed to the player who has the rabbit at the end of the round."
                  ),
                  _c("br"),
                  _vm._v(
                    " The first player who shoots the lowest net score on a hole with no ties (out right winner) catches the rabbit."
                  ),
                  _c("br"),
                  _vm._v(
                    " The other players are trying to release the rabbit from the owner. Shoot the lowest net score on a hole with no ties, and the rabbit is then loose."
                  ),
                  _c("br"),
                  _vm._v(
                    " Once the rabbit is loose again all players are able to catch the rabbit. Makes for some interesting closing holes."
                  ),
                  _c("br"),
                  _vm._v(
                    " If playing game every 9 holes, rabbit is released at end of 9 and is available to catch starting at 10. Otherwise 1 rabbit per 18 holes. "
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.contest == "skin"
            ? _c("div", [
                _c("b", [_vm._v("Skins")]),
                _c("br"),
                _c("p", [
                  _vm._v(
                    " Skins are for a pre-determined about of on Money that make up the pot."
                  ),
                  _c("br"),
                  _vm._v(" Single Lowest net or gross scores will win."),
                  _c("br"),
                  _vm._v(" Skins won will be displayed -"),
                  _c("br"),
                  _vm._v(" Tied skins will be shown as to lowest ties "),
                ]),
              ])
            : _vm._e(),
          +_vm.contest == 21
            ? _c("div", [
                _c("p", [
                  _vm._v(" Wolf: 4 person game only"),
                  _c("br"),
                  _vm._v(
                    " Be the player with the most points at the end of the round"
                  ),
                  _c("br"),
                  _vm._v(
                    " Players rotate on every hole of who tees off first, second and third last player teeing off being the 'Wolf'"
                  ),
                  _c("br"),
                  _vm._v(" You will be the wolf every fourth hole."),
                  _c("br"),
                  _vm._v(" Scoring:"),
                  _c("br"),
                  _vm._v(
                    " Once each player in the group hits his or her tee shot, the Wolf decides whether or not to take any of the players on thier team for the hole."
                  ),
                  _c("br"),
                  _vm._v(
                    " If not, the Wolf plays the hole as the 'Lone Wolf' -- in which case the objective is to beat the three other players with the lowest net score on the hole."
                  ),
                  _c("br"),
                  _vm._v(
                    " Every hole is played as a net best ball with only the best score of each team being used."
                  ),
                  _c("br"),
                  _vm._v(
                    " - If the Wolf chose a partner and they win the hole, they each receive two points"
                  ),
                  _c("br"),
                  _vm._v(
                    " - If the non-Wolf partners win the hole, they get three points apiece"
                  ),
                  _c("br"),
                  _vm._v(
                    " - If the Lone Wolf beats all the other players, they receives four points"
                  ),
                  _c("br"),
                  _vm._v(
                    " - If the Lone Wolf gets beat by any player in the group, everyone in the group except the Lone Wolf receives one point."
                  ),
                  _c("br"),
                  _vm._v(
                    ' If wolf goes "Blind Wolf" declaring before the hole that you\'re going to play the hole alone without a partner before the tee shots are even hit.'
                  ),
                  _c("br"),
                  _vm._v(
                    " If the Blind Wolf beats all the other players, they receives six points"
                  ),
                  _c("br"),
                  _vm._v(
                    " If the Blind Wolf gets beat by any player in the group, everyone in the group except the Lone Wolf receives two point."
                  ),
                  _c("br"),
                ]),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }