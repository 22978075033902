var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { overflow: "auto" } }, [
    _vm._m(0),
    _vm.scores.current && _vm.scores.current.team1 && _vm.scores.current.team2
      ? _c("div", { staticClass: "mb-4" }, [
          _vm._v(" Current Teams: "),
          _c("b", [_vm._v(_vm._s(_vm.scores.current.team1))]),
          _vm._v(" vs. "),
          _c("b", [_vm._v(_vm._s(_vm.scores.current.team2))]),
        ])
      : _vm._e(),
    _c(
      "table",
      { staticClass: "text-center table-lines" },
      [
        _vm._l(_vm.orderedScores, function (s, k) {
          return [
            _c("tr", { key: s.key }, [
              _c("td", { class: s.team1.class }, [
                _vm._v(_vm._s(s.team1.name)),
              ]),
              _c("td", [_vm._v(_vm._s(k == 0 ? "Holes" : ""))]),
              _c("td", { class: s.team2.class }, [
                _vm._v(_vm._s(s.team2.name)),
              ]),
            ]),
            _c("tr", { key: s.key, class: s.dormie ? "dormie" : "" }, [
              _c("td", { class: _vm.scoreColor(s.team1.score) }, [
                _c("b", [_vm._v(_vm._s(_vm._f("scoreFormat")(s.team1.score)))]),
              ]),
              _c("td", { class: _vm.middleColor(s, k) }, [
                _vm._v(
                  _vm._s(s.dormie ? "D" : "") +
                    _vm._s(s.done ? s.holes.start + "-" + s.holes.end : "") +
                    _vm._s(_vm.isNew(s) && k == 0 ? "NEW" : "")
                ),
              ]),
              _c("td", { class: _vm.scoreColor(s.team2.score) }, [
                _c("b", [_vm._v(_vm._s(_vm._f("scoreFormat")(s.team2.score)))]),
              ]),
            ]),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center h5" }, [
      _c("b", [_vm._v("Sixes")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }