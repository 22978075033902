var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "nav-location" },
    [
      _c(
        "b-navbar",
        { staticClass: "bg-light", attrs: { toggleable: "lg", type: "light" } },
        [
          _c("b-navbar-brand", { attrs: { to: "/home" } }, [
            _c("img", {
              staticClass: "img-responsive",
              staticStyle: { margin: "0 auto" },
              attrs: { src: "/img/desertnomadstitle.png", height: "35px;" },
            }),
          ]),
          _vm.showScoreButton &&
          _vm.showBackToScoring &&
          !_vm.$route.path.match(/^\/leaderboard\/.*/) &&
          _vm.$route.path !== "/login" &&
          _vm.outingInProgress
            ? _c(
                "b-button",
                {
                  staticClass: "green-button back-to-scoring",
                  on: { click: _vm.goToCurrentOuting },
                },
                [_vm._v("Score ")]
              )
            : _vm._e(),
          _c("b-navbar-toggle", {
            attrs: { target: "nav-collapse" },
            on: { click: _vm.toggleDropdown },
          }),
          _c(
            "b-collapse",
            {
              attrs: { id: "nav-collapse", "is-nav": "" },
              on: { show: _vm.onDropdownOpen, hide: _vm.onDropdownClose },
            },
            [
              _c(
                "b-navbar-nav",
                [
                  _vm.ifShowNavElement("/postRound")
                    ? _c(
                        "b-nav-item",
                        {
                          staticClass: "text-center navItem",
                          attrs: {
                            active: _vm.curtRouteTitle == "Post Round",
                            to: "/postRound",
                          },
                        },
                        [_vm._v("Post Round")]
                      )
                    : _vm._e(),
                  _vm.ifShowNavElement("/currentOutings")
                    ? _c(
                        "b-nav-item",
                        {
                          staticClass: "text-center navItem",
                          attrs: {
                            active: _vm.curtRouteTitle == "Current Outings",
                            to: "/currentOutings",
                          },
                        },
                        [_vm._v("Current Outings")]
                      )
                    : _vm._e(),
                  _vm.ifShowNavElement("/handicapLookup")
                    ? _c(
                        "b-nav-item",
                        {
                          staticClass: "text-center navItem",
                          attrs: {
                            active: _vm.curtRouteTitle == "Handicap Lookup",
                            to: "/handicapLookup",
                          },
                        },
                        [_vm._v("Handicap Lookup")]
                      )
                    : _vm._e(),
                  _vm.ifShowNavElement("/visitors")
                    ? _c(
                        "b-nav-item",
                        {
                          staticClass: "text-center navItem",
                          attrs: {
                            active: _vm.curtRouteTitle == "Visitors",
                            to: "/visitors",
                          },
                        },
                        [_vm._v("Visitors")]
                      )
                    : _vm._e(),
                  _vm.ifShowNavElement("/directory")
                    ? _c(
                        "b-nav-item",
                        {
                          staticClass: "text-center navItem",
                          attrs: {
                            active: _vm.curtRouteTitle == "Directory",
                            to: "/directory",
                          },
                        },
                        [_vm._v("Directory")]
                      )
                    : _vm._e(),
                  _vm.username
                    ? _c(
                        "b-nav-item",
                        {
                          staticClass: "text-center navItem",
                          attrs: {
                            active: _vm.curtRouteTitle == "View Courses",
                            to: "/viewCourses",
                          },
                        },
                        [_vm._v("Courses")]
                      )
                    : _vm._e(),
                  _vm.ifShowNavElement("/contactUs")
                    ? _c(
                        "b-nav-item",
                        {
                          staticClass: "text-center navItem",
                          attrs: {
                            active: _vm.curtRouteTitle == "Contact US",
                            to: "/contactUs",
                          },
                        },
                        [_vm._v("Contact US")]
                      )
                    : _vm._e(),
                  _c(
                    "b-nav-item",
                    {
                      staticClass: "text-center navItem",
                      attrs: { href: "https://www.slydercup.com/" },
                    },
                    [_vm._v("Slyder Cup")]
                  ),
                  _vm.ifShowNavElement("/leaderboard")
                    ? _c(
                        "b-nav-item",
                        {
                          staticClass: "text-center navItem",
                          attrs: {
                            active: _vm.curtRouteTitle == "Leaderboard",
                            to: "/leaderboard",
                          },
                        },
                        [_vm._v("Leaderboard")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "b-navbar-nav",
                { staticClass: "ml-auto align-items-center" },
                [
                  _vm.showScoreButton &&
                  !_vm.showBackToScoring &&
                  !_vm.$route.path.match(/^\/leaderboard\/.*/) &&
                  _vm.$route.path !== "/login" &&
                  _vm.outingInProgress
                    ? _c(
                        "b-button",
                        {
                          staticClass: "green-button",
                          on: { click: _vm.goToCurrentOuting },
                        },
                        [_vm._v("Back to Scoring ")]
                      )
                    : _vm._e(),
                  !_vm.username
                    ? _c(
                        "b-nav-item",
                        {
                          attrs: {
                            active: _vm.curtRouteTitle == "Login",
                            to: "/login",
                          },
                        },
                        [_vm._v("Login")]
                      )
                    : _c(
                        "b-nav-item-dropdown",
                        {
                          staticClass: "text-center",
                          attrs: { right: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "button-content",
                              fn: function () {
                                return [
                                  _c("em", [_vm._v(_vm._s(_vm.username))]),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _c("b-dropdown-item", { attrs: { to: "settings" } }, [
                            _vm._v("My Account"),
                          ]),
                          _c(
                            "PrintCard",
                            [[_c("b-dropdown-item", [_vm._v("Print Card")])]],
                            2
                          ),
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: { to: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.logout.apply(null, arguments)
                                },
                              },
                            },
                            [_vm._v("Sign Out")]
                          ),
                        ],
                        1
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }