var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.roundReviews
    ? _c("div", { staticClass: "card mb-2" }, [
        _c(
          "div",
          { staticClass: "card-body", staticStyle: { "padding-top": "8px" } },
          [
            _c(
              "div",
              { staticClass: "text-right table-title" },
              [
                _c(
                  "v-popover",
                  [
                    _c("span", [
                      _c("i", {
                        staticClass: "fa fa-question-circle",
                        staticStyle: { "margin-top": "0", "padding-top": "0" },
                      }),
                    ]),
                    _c("template", { slot: "popover" }, [
                      _vm._v(
                        " Tap green links on rounds and indexes for detail on what makes up that number "
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-4 order-1" }, [
                _c("div", { staticClass: "row my-0" }, [
                  _c(
                    "div",
                    { staticClass: "col-8" },
                    [
                      _vm._v(" Current USGA Index: "),
                      _vm.roundReviews.cap != 0
                        ? _c(
                            "v-popover",
                            { staticClass: "d-inline-block" },
                            [
                              _c("span", [
                                _c("i", {
                                  staticClass: "fa fa-question-circle",
                                  staticStyle: {
                                    "margin-top": "0",
                                    "padding-top": "0",
                                  },
                                }),
                              ]),
                              _c("template", { slot: "popover" }, [
                                _vm._v(" Handicap Cap:"),
                                _c("br"),
                                _c("br"),
                                _vm._v(" Soft Cap (S):"),
                                _c("br"),
                                _vm._v(
                                  " The soft cap will suppress the upward movement of a Handicap Index by 50 percent if a 3.0 stroke increase takes place within 12 months."
                                ),
                                _c("br"),
                                _c("br"),
                                _vm._v(" Hard Cap (H):"),
                                _c("br"),
                                _vm._v(
                                  " The hard cap will restrict upward movement if a 5.0 stroke increase takes place within 12 months. "
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "col-4 text-right" }, [
                    _vm.roundReviews.revisedHandicap == 0
                      ? _c("b", [
                          _c(
                            "a",
                            {
                              attrs: { href: "" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.onNavToDetail("usga")
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.roundReviews.handicap) + " "
                              ),
                            ]
                          ),
                          _vm.roundReviews.cap != 0
                            ? _c("div", { staticClass: "d-inline-block" }, [
                                _vm._v(
                                  " (" +
                                    _vm._s(
                                      _vm.roundReviews.cap == 1 ? "S" : "H"
                                    ) +
                                    ") "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _c("b", [
                          _c(
                            "a",
                            {
                              attrs: { href: "" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.onNavToDetail("usga")
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.roundReviews.revisedHandicap) +
                                  " R (" +
                                  _vm._s(_vm.roundReviews.handicap) +
                                  ") "
                              ),
                            ]
                          ),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.roundReviews.cap == 1
                                  ? "S"
                                  : _vm.roundReviews.cap == 2
                                  ? "H"
                                  : ""
                              ) +
                              " "
                          ),
                        ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "col-md-4 order-3 order-md-2" }, [
                _c("div", { staticClass: "row my-0" }, [
                  _c("div", { staticClass: "col-9" }, [
                    _vm._v("Avg. Score (Last 20 rounds):"),
                  ]),
                  _c("div", { staticClass: "col-3 text-right" }, [
                    _c("b", [
                      _c(
                        "a",
                        {
                          attrs: { href: "" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.onNavToDetail("avg")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.roundReviews.averageScore))]
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "col-md-4 order-4 order-md-3" }, [
                _c("div", { staticClass: "row my-0" }, [
                  _c("div", { staticClass: "col-8" }, [
                    _vm._v("Avg Holes Par or Less:"),
                  ]),
                  _c("div", { staticClass: "col-4 text-right" }, [
                    _c("b", [_vm._v(_vm._s(_vm.roundReviews.avgpar))]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "col-md-4 order-2 order-md-4" }, [
                _c("div", { staticClass: "row my-0" }, [
                  _c("div", { staticClass: "col-8" }, [
                    _vm._v("Lowest Index Last 12mo:"),
                  ]),
                  _c("div", { staticClass: "col-4 text-right" }, [
                    _c("b", [_vm._v(_vm._s(_vm.roundReviews.lowestIndex))]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "col-md-4 order-5" }, [
                _c("div", { staticClass: "row my-0" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "col-4 text-right" }, [
                    _c("b", [
                      _c(
                        "a",
                        {
                          attrs: { href: "" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.onNavToDetail("anti-handicap")
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.roundReviews.antihandicap) + " "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "col-md-4 order-5" }, [
                _c("div", { staticClass: "row my-0" }, [
                  _c("div", { staticClass: "col-8" }, [
                    _vm._v("Consistency Factor:"),
                  ]),
                  _c("div", { staticClass: "col-4 text-right" }, [
                    _c("b", [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatNumber")(
                            _vm.roundReviews.consistency,
                            "0,0.00"
                          )
                        )
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "col-md-4 order-5" }, [
                _c("div", { staticClass: "row my-0" }, [
                  _c("div", { staticClass: "col-9" }, [
                    _vm._v("Rounds Posted in Past Year:"),
                  ]),
                  _c("div", { staticClass: "col-3 text-right" }, [
                    _c("b", [
                      _c(
                        "a",
                        {
                          attrs: { href: "" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.onNavToDetail("round-posted-past-year")
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.roundReviews.rounds_past_year.total_rounds
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "col-md-4 order-5" }, [
                _c("div", { staticClass: "row my-0" }, [
                  _c("div", { staticClass: "col-9" }, [
                    _vm._v("Average Rounds Per Year:"),
                  ]),
                  _c("div", { staticClass: "col-3 text-right" }, [
                    _c("b", [_vm._v(_vm._s(_vm.roundReviews.rounds_per_year))]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "col-md-4 order-5" }, [
                _c("div", { staticClass: "row my-0" }, [
                  _c("div", { staticClass: "col-8" }, [
                    _vm._v("Total Rounds Posted:"),
                  ]),
                  _c("div", { staticClass: "col-4 text-right" }, [
                    _c("b", [
                      _c(
                        "a",
                        {
                          attrs: { href: "" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.onNavToDetail("total-rounds-posted")
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.roundReviews.num_rounds) + " "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "col-md-4 order-5" }, [
                _c("div", { staticClass: "row my-0" }, [
                  _c("div", { staticClass: "col-8" }, [
                    _vm._v("Eligible T-Scores :"),
                  ]),
                  _c("div", { staticClass: "col-4 text-right" }, [
                    _c("b", [
                      _vm._v(
                        _vm._s(
                          _vm.roundReviews.eligibleTournamentScores
                            ? _vm.roundReviews.eligibleTournamentScores
                            : "N/A"
                        )
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "col-md-4 order-5" }, [
                _c("div", { staticClass: "row my-0" }, [
                  _c("div", { staticClass: "col-8" }, [
                    _vm._v("Tournament Handicap:"),
                  ]),
                  _c("div", { staticClass: "col-4 text-right" }, [
                    _c("b", [
                      _c(
                        "a",
                        {
                          attrs: { href: "" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.onNavToDetail("tournament-handicap")
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.roundReviews.tourneyhandicap) + " "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "col-md-4 order-5" }, [
                _c("div", { staticClass: "row my-0" }, [
                  _c("div", { staticClass: "col-8" }, [
                    _vm._v("Lowest T Differential:"),
                  ]),
                  _c("div", { staticClass: "col-4 text-right" }, [
                    _c("b", [
                      _vm._v(
                        _vm._s(
                          _vm.roundReviews.lowestTournamentScore
                            ? _vm.roundReviews.lowestTournamentScore
                            : "N/A"
                        )
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "col-md-4 order-5" }, [
                _c("div", { staticClass: "row my-0" }, [
                  _c("div", { staticClass: "col-9" }, [
                    _vm._v("Second Lowest T Differential:"),
                  ]),
                  _c("div", { staticClass: "col-3 text-right" }, [
                    _c("b", [
                      _vm._v(
                        _vm._s(
                          _vm.roundReviews.secondLowestTournamentScore
                            ? _vm.roundReviews.secondLowestTournamentScore
                            : "N/A"
                        )
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "col-md-4 order-5" }, [
                _c("div", { staticClass: "row my-0" }, [
                  _c("div", { staticClass: "col-9" }, [_vm._v("80 Plus:")]),
                  _c("div", { staticClass: "col-3 text-right" }, [
                    _c("b", [_vm._v(_vm._s(_vm.eightyPlus))]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "col-md-4 order-5" }, [
                _c("div", { staticClass: "row my-0" }, [
                  _c("div", { staticClass: "col-9" }, [
                    _vm._v("Handicap Chart:"),
                  ]),
                  _c("div", { staticClass: "col-3 text-right" }, [
                    _c("b", [
                      _c(
                        "a",
                        {
                          attrs: { href: "" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.handicapChart()
                            },
                          },
                        },
                        [_vm._v("View")]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "row" }, [
              _vm.show
                ? _c("div", { staticClass: "col-6 col-md-2" }, [
                    _vm._v("AGA #:"),
                  ])
                : _vm._e(),
              _vm.show
                ? _c("div", { staticClass: "col-6 col-md-2 text-right" }, [
                    _c("b", [_vm._v(_vm._s(_vm.roundReviews.aga))]),
                  ])
                : _vm._e(),
              _vm.show
                ? _c("div", { staticClass: "col-6 col-md-2" }, [
                    _vm._v("Dues:"),
                  ])
                : _vm._e(),
              _vm.show
                ? _c("div", { staticClass: "col-6 col-md-2 text-right" }, [
                    _c("b", [_vm._v(_vm._s(_vm.roundReviews.dues))]),
                  ])
                : _vm._e(),
            ]),
          ]
        ),
        _vm.show
          ? _c("div", { staticClass: "card-footer" }, [_vm._m(1)])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-8" }, [
      _vm._v("Anti-Handicap "),
      _c(
        "a",
        {
          attrs: {
            href: "http://www.popeofslope.com/guidelines/anti.html",
            target: "_blank",
          },
        },
        [_vm._v("(What?)")]
      ),
      _vm._v(": "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 col-md-6" }, [
        _c("i", [
          _vm._v("For a handicap graph for this golfer, click "),
          _c(
            "a",
            {
              attrs: {
                href: "handicap-graph.php?golfer_id={{golfer_selected}",
              },
            },
            [_vm._v("here")]
          ),
        ]),
        _vm._v(". "),
      ]),
      _c("div", { staticClass: "col-12 col-md-6 right-bottom-text" }, [
        _c("i", [
          _vm._v("Handicap Revision table for this golfer click "),
          _c(
            "a",
            {
              attrs: {
                href: "handicap_table.php?user_id={{golfer_selected}",
                target: "_blank",
              },
            },
            [_vm._v("here")]
          ),
        ]),
        _vm._v(". "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }