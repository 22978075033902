var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { width: "100%" } },
    [
      _vm.title
        ? _c(
            "div",
            { staticClass: "card mb-1 stick-box" },
            [
              _c(
                "div",
                { staticClass: "card-header text-center stick-title-box" },
                [
                  _c("i", {
                    staticClass: "fa fa-arrow-circle-left back-icon",
                    on: {
                      click: function ($event) {
                        return _vm.$router.go(-1)
                      },
                    },
                  }),
                  _c("b", { staticClass: "stick-title" }, [
                    _vm._v(_vm._s(_vm.title)),
                  ]),
                ]
              ),
              _vm._t("selectedGolfers"),
            ],
            2
          )
        : _vm._e(),
      _vm._t("filter"),
      _c("div", { staticClass: "d-md-none" }, [
        _vm.usersInfo && _vm.usersInfo.results && _vm.usersInfo.results.length
          ? _c(
              "div",
              _vm._l(_vm.filteredList, function (user, i) {
                return _c("AimCard", {
                  key: i,
                  attrs: { user: user },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "col-12",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.selectUser(user)
                                    },
                                  },
                                },
                                [
                                  _c("b", [
                                    _vm._v(_vm._s(user.firstname) + "  "),
                                  ]),
                                  _c("b", [
                                    _vm._v(" " + _vm._s(user.lastname)),
                                  ]),
                                  _vm.objExistedInSelectedArrayMixin(
                                    _vm.selectedUsers,
                                    user
                                  )
                                    ? _c("AimSelectedIcon", {
                                        staticClass: "float-right",
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
              1
            )
          : _c("div", { staticClass: "card" }, [_vm._m(0)]),
      ]),
      _vm.usersInfo && _vm.usersInfo.results
        ? _c("div", { staticClass: "card d-none d-md-flex" }, [
            _c("div", { staticClass: "table-responsive" }, [
              _c("table", { staticClass: "table mb-0 table-bordered" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th", { staticClass: "text-center align-middle" }, [
                      _c(
                        "a",
                        {
                          attrs: { href: "" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.changeSortTo("name")
                            },
                          },
                        },
                        [_vm._v("Name")]
                      ),
                    ]),
                    _c("th", { staticClass: "text-center align-middle" }, [
                      _vm._v("Email"),
                    ]),
                    _c("th", { staticClass: "text-center align-middle" }, [
                      _vm._v("Phone "),
                    ]),
                    _c("th", { staticClass: "text-center align-middle" }, [
                      _vm._v("State "),
                    ]),
                    _c("th", { staticClass: "text-center align-middle" }, [
                      _c(
                        "a",
                        {
                          attrs: { href: "" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.changeSortTo("handicapindex")
                            },
                          },
                        },
                        [_vm._v("Handicap")]
                      ),
                    ]),
                    _c("th", { staticClass: "text-center align-middle" }, [
                      _c(
                        "a",
                        {
                          attrs: { href: "" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.changeSortTo("parorbetter")
                            },
                          },
                        },
                        [_vm._v("Par+")]
                      ),
                    ]),
                    _c("th", { staticClass: "text-center" }, [
                      _c(
                        "a",
                        {
                          attrs: { href: "" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.changeSortTo("numrounds")
                            },
                          },
                        },
                        [_vm._v("Revolving Year's Round")]
                      ),
                    ]),
                    _c("th", [_vm._v("Rounds Together")]),
                    _c("th", [_vm._v("Rounds Course")]),
                    _c("th"),
                  ]),
                ]),
                _vm.usersInfo.results.length
                  ? _c(
                      "tbody",
                      _vm._l(_vm.filteredList, function (user) {
                        return _c("tr", [
                          _c(
                            "td",
                            { staticClass: "text-center align-middle" },
                            [
                              _c(
                                "router-link",
                                { attrs: { to: `handicapLookup/${user.id}` } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      user.lastname + " " + user.firstname
                                    ) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            { staticClass: "text-center align-middle" },
                            [
                              _c(
                                "a",
                                { attrs: { href: `mailto:${user.email}` } },
                                [_vm._v(_vm._s(user.email) + " ")]
                              ),
                            ]
                          ),
                          _c(
                            "td",
                            { staticClass: "text-center align-middle" },
                            [
                              _vm._v(
                                _vm._s(user.phonenum ? user.phonenum : "N/A")
                              ),
                            ]
                          ),
                          _c(
                            "td",
                            { staticClass: "text-center align-middle" },
                            [
                              _c("span", [
                                _vm._v(_vm._s(user.state ? user.state : "N/A")),
                              ]),
                            ]
                          ),
                          _c(
                            "td",
                            { staticClass: "text-center align-middle" },
                            [
                              _vm._v(
                                _vm._s(
                                  user.handicapindex && user.handicapindex != 0
                                    ? user.handicapindex
                                    : ""
                                )
                              ),
                            ]
                          ),
                          _c(
                            "td",
                            { staticClass: "text-center align-middle" },
                            [_vm._v(_vm._s(user.parorbetteravg))]
                          ),
                          _c(
                            "td",
                            { staticClass: "text-center align-middle" },
                            [_vm._v(_vm._s(user.numrounds))]
                          ),
                          _c(
                            "td",
                            { staticClass: "text-center align-middle" },
                            [_vm._v(_vm._s(user.played))]
                          ),
                          _c(
                            "td",
                            { staticClass: "text-center align-middle" },
                            [_vm._v(_vm._s(user.courseplayed))]
                          ),
                          _c(
                            "td",
                            { staticClass: "text-center align-middle" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-secondary",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.selectUser(user)
                                    },
                                  },
                                },
                                [_vm._v("Click To Select")]
                              ),
                            ]
                          ),
                        ])
                      }),
                      0
                    )
                  : _c("tbody", [_vm._m(1)]),
              ]),
            ]),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-body text-text-center" }, [
      _c("b", [_vm._v("No Courses")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-text-center", attrs: { colspan: "8" } }, [
        _c("b", [_vm._v("No Golfers")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }