var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-12 mx-auto align-middle" },
        [
          _c("CourseList", {
            attrs: {
              postingRound: true,
              filters: _vm.filters,
              courseInfo: _vm.courseInfo,
              title: "Step 1 : Select Round Course",
            },
            on: {
              updateFilters: function ($event) {
                return _vm.onGetFilters($event)
              },
            },
            scopedSlots: _vm._u([
              {
                key: "filter",
                fn: function () {
                  return [
                    _c("CourseFilter", {
                      attrs: { filters: _vm.filters },
                      on: {
                        updateFilter: function ($event) {
                          return _vm.onGetFilters($event)
                        },
                      },
                    }),
                    _vm.selectedCourses && _vm.selectedCourses.length
                      ? _c("div", { staticClass: "card mb-1" }, [
                          _c("div", { staticClass: "card-header" }, [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-12" }, [
                                _c("b", [_vm._v("Selected Course")]),
                              ]),
                            ]),
                            _c("hr"),
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col-12" },
                                [
                                  _vm._l(
                                    _vm.selectedCourses,
                                    function (course, index) {
                                      return _c("AimBadge", {
                                        key: index,
                                        staticClass: "mr-2 mb-1",
                                        attrs: { infos: [course.name] },
                                        on: {
                                          updateDelete: function ($event) {
                                            return _vm.onGetDelete(
                                              $event,
                                              course
                                            )
                                          },
                                        },
                                      })
                                    }
                                  ),
                                  _vm.selectedCourses && _vm.selectedCourses[0]
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-info float-right",
                                          on: {
                                            click: function ($event) {
                                              return _vm.goToNextStep()
                                            },
                                          },
                                        },
                                        [_vm._v("Go To Next Step")]
                                      )
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c("SelectCourseModal", {
        attrs: { course: _vm.selectedCourses[0] },
        on: {
          updateConfirm: function ($event) {
            return _vm.onGetConfirm($event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }