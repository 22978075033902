var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card mb-1 alert-danger" }, [
    _c("div", { staticClass: "card-body" }, [
      _c("h5", { staticClass: "card-title" }, [_vm._v(_vm._s(_vm.title))]),
      _c("p", { staticClass: "card-text" }, [
        _c(
          "ul",
          _vm._l(_vm.errors, function (error, index) {
            return _c("li", { key: index }, [_vm._v(_vm._s(error))])
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }