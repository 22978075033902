var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c("div", { staticClass: "d-block text-center" }, [
        _c(
          "a",
          {
            staticStyle: {},
            attrs: { href: "https://desertnomads.org/leaderboard/" },
          },
          [_vm._v("Link to tournament leaderboard")]
        ),
      ]),
      _c(
        "b-container",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "h2 text-center", attrs: { cols: "12" } },
                [_vm._v(" Nomad Scoring ")]
              ),
            ],
            1
          ),
          _vm.loggedIn
            ? _c(
                "b-card",
                [
                  _c(
                    "b-row",
                    [
                      _vm.myoutings.length > 0
                        ? _c(
                            "b-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "b-row",
                                _vm._l(_vm.myoutings, function (o) {
                                  return _c(
                                    "b-col",
                                    {
                                      key: o.id,
                                      staticClass: "mt-4",
                                      attrs: { cols: "12", md: "6", lg: "4" },
                                    },
                                    [
                                      _c("OutingCard", {
                                        attrs: { outing: o },
                                        on: {
                                          outingCancelled:
                                            _vm.handleOutingCancelled,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _c("b-col", { attrs: { cols: "12" } }, [
                            _vm._v(
                              " You currently have no rounds being scored. Create one "
                            ),
                            _c("a", { attrs: { href: "/tournament" } }, [
                              _vm._v("here"),
                            ]),
                          ]),
                    ],
                    1
                  ),
                  _vm.otheroutings.length > 0
                    ? _c(
                        "b-row",
                        { staticClass: "mt-4" },
                        [
                          _c(
                            "b-col",
                            {
                              staticClass: "h2 text-center",
                              attrs: { cols: "12" },
                            },
                            [_vm._v(" Other Leaderboards ")]
                          ),
                          _vm.otheroutings.length > 0
                            ? _c(
                                "b-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "b-row",
                                    _vm._l(_vm.otheroutings, function (o) {
                                      return _c(
                                        "b-col",
                                        {
                                          key: o.id,
                                          staticClass: "mt-4",
                                          attrs: {
                                            cols: "12",
                                            md: "6",
                                            lg: "4",
                                          },
                                        },
                                        [
                                          _c("OutingCard", {
                                            attrs: {
                                              outing: o,
                                              flagUser: _vm.user_id,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _c("b-col", { attrs: { cols: "12" } }, [
                                _vm._v(
                                  " There are currently no other outings happening that can be viewed. "
                                ),
                              ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _c(
                "b-card",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { cols: "12", md: "4" } }, [
                        _vm._v(" Outing code "),
                      ]),
                      _c("b-col", { attrs: { cols: "12", md: "8" } }, [
                        _c(
                          "form",
                          {
                            on: {
                              submit: function ($event) {
                                $event.preventDefault()
                                return _vm.setCode()
                              },
                            },
                          },
                          [
                            _c("b-input", {
                              model: {
                                value: _vm.code,
                                callback: function ($$v) {
                                  _vm.code = $$v
                                },
                                expression: "code",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-4" },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { size: "lg", block: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.setCode()
                                },
                              },
                            },
                            [_vm._v("Score outing")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }