var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-container", { attrs: { fluid: "" } }, [
    _c("h3", { staticClass: "text-center" }, [_vm._v(" Group Scoring ")]),
    _c("div", { staticClass: "row" }, [
      _vm.step == 1
        ? _c(
            "div",
            { staticClass: "col-12 col-md-8 mx-auto mt-2 align-middle" },
            [
              _c(
                "b-card",
                [
                  _c("div", { staticClass: "form-group row" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col-4 col-md-3 col-form-label",
                        attrs: { for: "course" },
                      },
                      [_vm._v("Course")]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-8 col-md-9 pl-0" },
                      [
                        _c("course-type-ahead", {
                          attrs: {
                            "hide-label": "",
                            courses: _vm.courses,
                            selectedCourseId: _vm.teetime.course_id,
                          },
                          on: {
                            "select-course": _vm.onGetSearchTeeboxesFilter,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm.teetime.course_id
                    ? _c("div", { staticClass: "form-group row" }, [
                        _c(
                          "label",
                          {
                            staticClass: "col-4 col-md-3 col-form-label",
                            attrs: { for: "course" },
                          },
                          [_vm._v("Teebox")]
                        ),
                        _c("div", { staticClass: "col-8 col-md-9 pl-0" }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.teetime.teebox_id,
                                  expression: "teetime.teebox_id",
                                },
                              ],
                              staticClass: "custom-select mb-2 mr-sm-2 mb-sm-0",
                              attrs: { id: "teebox" },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.teetime,
                                    "teebox_id",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "option",
                                {
                                  attrs: { disabled: "" },
                                  domProps: { value: null },
                                },
                                [_vm._v("N/A")]
                              ),
                              _vm._l(_vm.teeboxes, function (teebox) {
                                return _c(
                                  "option",
                                  {
                                    key: teebox.id,
                                    domProps: { value: teebox.id },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(teebox.description) +
                                        " (" +
                                        _vm._s(teebox.slope) +
                                        "/" +
                                        _vm._s(teebox.rating) +
                                        ") "
                                    ),
                                  ]
                                )
                              }),
                            ],
                            2
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "text-center", attrs: { cols: "12" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                disabled:
                                  !_vm.teetime.course_id ||
                                  !_vm.teetime.teebox_id,
                              },
                              on: {
                                click: function ($event) {
                                  _vm.step = 2
                                },
                              },
                            },
                            [_vm._v("Continue")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.existingTeetime
                ? _c(
                    "b-row",
                    { staticClass: "mt-4" },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "b-button",
                            { attrs: { block: "" }, on: { click: _vm.cancel } },
                            [_vm._v("Cancel")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.step == 2
        ? _c(
            "div",
            { staticClass: "col-12 col-md-8 mx-auto mt-2 align-middle" },
            [
              _c(
                "b-card",
                [
                  _c("div", { staticClass: "row mb-4 mt-1 ml-n4" }, [
                    _c("div", { staticClass: "col h4" }, [
                      _c("i", {
                        staticClass: "fa fa-arrow-circle-left back-icon",
                        on: {
                          click: function ($event) {
                            _vm.step = 1
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "card mb-1 stick-box" }, [
                    _vm.golfers && _vm.golfers.length
                      ? _c("div", { staticClass: "card-header" }, [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-12" }, [
                              _c("b", [_vm._v("Selected Golfers  ")]),
                              _c("b", [
                                _vm._v("(" + _vm._s(_vm.golfers.length) + ")"),
                              ]),
                            ]),
                          ]),
                          _c("hr"),
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-12" },
                              [
                                _vm._l(_vm.golfers, function (g, index) {
                                  return _c("AimBadge", {
                                    key: index,
                                    staticClass: "mr-2 mb-1",
                                    attrs: {
                                      infos: [g.firstname, g.lastname],
                                      noDelete: +g.user_id == +_vm.user.id,
                                    },
                                    on: {
                                      updateDelete: function ($event) {
                                        return _vm.deselectUser(g)
                                      },
                                    },
                                  })
                                }),
                                _c("div", { staticClass: "float-right" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-info",
                                      on: {
                                        click: function ($event) {
                                          _vm.step = 3
                                        },
                                      },
                                    },
                                    [_vm._v("Continue")]
                                  ),
                                  _c("br"),
                                  _c("small", [
                                    _vm._v("*Add visitor on next screen"),
                                  ]),
                                ]),
                              ],
                              2
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                  _c("GolferFilter", {
                    attrs: { filters: _vm.filters },
                    on: {
                      updateFilter: function ($event) {
                        return _vm.onGetFilters($event)
                      },
                    },
                  }),
                  _c("div", { staticClass: "card" }, [
                    _c(
                      "div",
                      { staticClass: "table-responsive card-header p-0" },
                      [
                        _c(
                          "table",
                          { staticClass: "table mb-0 table-bordered" },
                          [
                            _c("thead", [
                              _c("tr", [
                                _c(
                                  "th",
                                  { staticClass: "text-center align-middle" },
                                  [_vm._v("Name")]
                                ),
                              ]),
                            ]),
                            _vm.usersInfo.results.length
                              ? _c(
                                  "tbody",
                                  _vm._l(_vm.golferList, function (user) {
                                    return _c("tr", { key: user.id }, [
                                      _c(
                                        "td",
                                        {
                                          staticClass: "d-none d-sm-table-cell",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                user.firstname +
                                                  " " +
                                                  user.lastname
                                              ) +
                                              " "
                                          ),
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-secondary float-right",
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.selectUser(user)
                                                },
                                              },
                                            },
                                            [_vm._v("Click To Select")]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "d-table-cell d-sm-none",
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-secondary btn-block",
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.selectUser(user)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  user.firstname +
                                                    " " +
                                                    user.lastname
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ])
                                  }),
                                  0
                                )
                              : _c("tbody", [
                                  _c("tr", [
                                    _c(
                                      "td",
                                      {
                                        staticClass: "text-text-center",
                                        attrs: { colspan: "8" },
                                      },
                                      [_c("b", [_vm._v("No Golfers")])]
                                    ),
                                  ]),
                                ]),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _vm.existingTeetime
                ? _c(
                    "b-row",
                    { staticClass: "mt-4" },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "b-button",
                            { attrs: { block: "" }, on: { click: _vm.cancel } },
                            [_vm._v("Cancel")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.step == 3
        ? _c(
            "div",
            { staticClass: "col-12 col-md-8 mx-auto mt-2 align-middle" },
            [
              _c(
                "b-card",
                [
                  _c("div", { staticClass: "row mb-4 mt-1 ml-n4" }, [
                    _c("div", { staticClass: "col h4" }, [
                      _c("i", {
                        staticClass: "fa fa-arrow-circle-left back-icon",
                        on: {
                          click: function ($event) {
                            _vm.step = 2
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "text-center mb-4 h3" }, [
                    _vm._v(" Guests "),
                  ]),
                  _vm.golfers.filter((i) => i.user_id == 0).length > 0
                    ? _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { cols: "12" } }, [
                            _c(
                              "table",
                              {
                                staticClass:
                                  "table mb-0 table-bordered d-none d-md-table",
                              },
                              [
                                _c("thead", [
                                  _c("tr", [
                                    _c(
                                      "th",
                                      {
                                        staticClass: "text-center align-middle",
                                      },
                                      [_vm._v("Info")]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        staticClass: "text-center align-middle",
                                      },
                                      [_vm._v("Index")]
                                    ),
                                    _c("th", {
                                      staticClass: "text-center align-middle",
                                    }),
                                  ]),
                                ]),
                                _c(
                                  "tbody",
                                  _vm._l(
                                    _vm.golfers.filter((i) => i.user_id == 0),
                                    function (u, index) {
                                      return _c("tr", { key: index }, [
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "text-center align-middle",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(u.firstname) +
                                                " " +
                                                _vm._s(u.lastname)
                                            ),
                                            _c("br"),
                                            _vm._v(_vm._s(u.email) + " "),
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "text-center align-middle",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(u.handicapindex) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "text-center align-middle",
                                          },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                staticClass: "float-right",
                                                attrs: { variant: "danger" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deselectUser(u)
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fa fa-times-circle",
                                                }),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                            _c(
                              "table",
                              {
                                staticClass:
                                  "table mb-0 table-bordered d-table d-md-none",
                              },
                              [
                                _c(
                                  "tbody",
                                  _vm._l(
                                    _vm.golfers.filter((i) => i.user_id == 0),
                                    function (u, index) {
                                      return _c("tr", { key: index }, [
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "text-center align-middle",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(u.firstname) +
                                                " " +
                                                _vm._s(u.lastname)
                                            ),
                                            _c("br"),
                                            _vm._v(" " + _vm._s(u.email)),
                                            _c("br"),
                                            _vm._v(
                                              " " + _vm._s(u.handicapindex)
                                            ),
                                            _c("br"),
                                            _c(
                                              "b-button",
                                              {
                                                staticClass: "float-right",
                                                attrs: {
                                                  variant: "danger",
                                                  block: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deselectUser(u)
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fa fa-times-circle",
                                                }),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-row",
                    { staticClass: "mt-4" },
                    [
                      _c(
                        "b-col",
                        { staticClass: "text-center", attrs: { cols: "12" } },
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal.guestadd",
                                  modifiers: { guestadd: true },
                                },
                              ],
                              staticClass: "mb-2",
                              attrs: { variant: "success", block: "" },
                            },
                            [_vm._v("Add guest")]
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: { block: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.afterGuest()
                                },
                              },
                            },
                            [_vm._v("Continue")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.existingTeetime
                ? _c(
                    "b-row",
                    { staticClass: "mt-4" },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "b-button",
                            { attrs: { block: "" }, on: { click: _vm.cancel } },
                            [_vm._v("Cancel")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-modal",
                {
                  attrs: {
                    id: "guestadd",
                    "ok-title": "Add",
                    "ok-disabled":
                      !_vm.guestUser.firstname ||
                      _vm.guestUser.firstname == "" ||
                      !_vm.guestUser.lastname ||
                      _vm.guestUser.lastname == "" ||
                      !_vm.guestUser.email ||
                      _vm.guestUser.email == "" ||
                      _vm.guestUser.handicapindex == null ||
                      _vm.guestUser.handicapindex == "",
                  },
                  on: { hidden: _vm.resetGuestAdd, ok: _vm.addGuest },
                },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: "Firstname" } },
                    [
                      _c("b-input", {
                        model: {
                          value: _vm.guestUser.firstname,
                          callback: function ($$v) {
                            _vm.$set(_vm.guestUser, "firstname", $$v)
                          },
                          expression: "guestUser.firstname",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    { attrs: { label: "Lastname" } },
                    [
                      _c("b-input", {
                        model: {
                          value: _vm.guestUser.lastname,
                          callback: function ($$v) {
                            _vm.$set(_vm.guestUser, "lastname", $$v)
                          },
                          expression: "guestUser.lastname",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    { attrs: { label: "E-mail" } },
                    [
                      _c("b-input", {
                        model: {
                          value: _vm.guestUser.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.guestUser, "email", $$v)
                          },
                          expression: "guestUser.email",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    { attrs: { label: "Handicap" } },
                    [
                      _c("b-input", {
                        on: {
                          keypress: function ($event) {
                            return _vm.numberInput($event, 2)
                          },
                        },
                        model: {
                          value: _vm.guestUser.handicapindex,
                          callback: function ($$v) {
                            _vm.$set(_vm.guestUser, "handicapindex", $$v)
                          },
                          expression: "guestUser.handicapindex",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.step == 4
        ? _c(
            "div",
            { staticClass: "col-12 col-md-8 mx-auto mt-2 align-middle" },
            [
              _c("b-card", { attrs: { "body-class": "p-0" } }, [
                _c("div", { staticClass: "row mb-4 mt-4 ml-n1" }, [
                  _c("div", { staticClass: "col h4" }, [
                    _c("i", {
                      staticClass: "fa fa-arrow-circle-left back-icon",
                      on: {
                        click: function ($event) {
                          _vm.step = 3
                        },
                      },
                    }),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "text-center font-weight-bold my-4 h3" },
                  [_vm._v(" Set Game Format ")]
                ),
                _c(
                  "div",
                  { staticClass: "container" },
                  [
                    _c(
                      "b-form-group",
                      [
                        _c(
                          "b-form-radio",
                          {
                            attrs: { value: 0 },
                            model: {
                              value: _vm.teetime.contest_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.teetime, "contest_id", $$v)
                              },
                              expression: "teetime.contest_id",
                            },
                          },
                          [_vm._v("Normal (Score Only)")]
                        ),
                        _vm.golfers.length == 4
                          ? _c(
                              "b-form-radio",
                              {
                                attrs: { value: 21 },
                                model: {
                                  value: _vm.teetime.contest_id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.teetime, "contest_id", $$v)
                                  },
                                  expression: "teetime.contest_id",
                                },
                              },
                              [
                                _vm._v(" Wolf "),
                                _c(
                                  "ContestInfo",
                                  {
                                    staticClass: "ml-2",
                                    attrs: { contest: "21" },
                                  },
                                  [_vm._v("Info")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.golfers.length == 4
                          ? _c(
                              "b-form-radio",
                              {
                                attrs: { value: 14 },
                                model: {
                                  value: _vm.teetime.contest_id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.teetime, "contest_id", $$v)
                                  },
                                  expression: "teetime.contest_id",
                                },
                              },
                              [
                                _vm._v(" Sixes "),
                                _c(
                                  "ContestInfo",
                                  {
                                    staticClass: "ml-2",
                                    attrs: { contest: "14" },
                                  },
                                  [_vm._v("Info")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.golfers.length > 1
                          ? _c(
                              "b-form-radio",
                              {
                                attrs: { value: 16 },
                                model: {
                                  value: _vm.teetime.contest_id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.teetime, "contest_id", $$v)
                                  },
                                  expression: "teetime.contest_id",
                                },
                              },
                              [
                                _vm._v(" Stableford "),
                                _c(
                                  "ContestInfo",
                                  {
                                    staticClass: "ml-2",
                                    attrs: { contest: "16" },
                                  },
                                  [_vm._v("Info")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.golfers.length > 1 && _vm.teetime.contest_id == 17
                          ? _c(
                              "b-form-radio",
                              {
                                attrs: { value: 17 },
                                model: {
                                  value: _vm.teetime.contest_id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.teetime, "contest_id", $$v)
                                  },
                                  expression: "teetime.contest_id",
                                },
                              },
                              [
                                _vm._v(" Rabbit "),
                                _c(
                                  "ContestInfo",
                                  {
                                    staticClass: "ml-2",
                                    attrs: { contest: "18" },
                                  },
                                  [_vm._v("Info")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.golfers.length > 1 && _vm.teetime.contest_id != 17
                          ? _c(
                              "b-form-radio",
                              {
                                attrs: { value: 18 },
                                model: {
                                  value: _vm.teetime.contest_id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.teetime, "contest_id", $$v)
                                  },
                                  expression: "teetime.contest_id",
                                },
                              },
                              [
                                _vm._v(" Rabbit "),
                                _c(
                                  "ContestInfo",
                                  {
                                    staticClass: "ml-2",
                                    attrs: { contest: "18" },
                                  },
                                  [_vm._v("Info")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "pl-4" },
                          [
                            _vm.teetime.contest_id == 17 ||
                            _vm.teetime.contest_id == 18
                              ? _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-form-radio",
                                      {
                                        attrs: { value: 17 },
                                        model: {
                                          value: _vm.teetime.contest_id,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.teetime,
                                              "contest_id",
                                              $$v
                                            )
                                          },
                                          expression: "teetime.contest_id",
                                        },
                                      },
                                      [_vm._v(" 1 Rabbit each 9 holes ")]
                                    ),
                                    _c(
                                      "b-form-radio",
                                      {
                                        attrs: { value: 18 },
                                        model: {
                                          value: _vm.teetime.contest_id,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.teetime,
                                              "contest_id",
                                              $$v
                                            )
                                          },
                                          expression: "teetime.contest_id",
                                        },
                                      },
                                      [_vm._v(" 1 Rabbit for 18 holes ")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("b-form-group", [
                      _c("div", { staticClass: "form-check float-left" }, [
                        _c(
                          "label",
                          { staticClass: "form-check-label" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.skins,
                                  expression: "skins",
                                },
                              ],
                              staticClass: "form-check-input",
                              attrs: {
                                "true-value": 1,
                                "false-value": 0,
                                type: "checkbox",
                              },
                              domProps: {
                                checked: Array.isArray(_vm.skins)
                                  ? _vm._i(_vm.skins, null) > -1
                                  : _vm._q(_vm.skins, 1),
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.skins,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? 1 : 0
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 && (_vm.skins = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.skins = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.skins = $$c
                                  }
                                },
                              },
                            }),
                            _vm._v(" Skins "),
                            _c(
                              "ContestInfo",
                              {
                                staticClass: "ml-2",
                                attrs: { contest: "skin" },
                              },
                              [_vm._v("Info")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("br"),
                      _vm.skins == 1
                        ? _c("div", { staticClass: "form-group row pl-4" }, [
                            _c("div", { staticClass: "col-4 col-md-3" }, [
                              _c(
                                "div",
                                { staticClass: "form-check float-left" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "form-check-label" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.teetime.net_skins,
                                            expression: "teetime.net_skins",
                                          },
                                        ],
                                        staticClass: "form-check-input",
                                        attrs: {
                                          "true-value": 1,
                                          "false-value": 0,
                                          type: "checkbox",
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.teetime.net_skins
                                          )
                                            ? _vm._i(
                                                _vm.teetime.net_skins,
                                                null
                                              ) > -1
                                            : _vm._q(_vm.teetime.net_skins, 1),
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.teetime.net_skins,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? 1 : 0
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.teetime,
                                                    "net_skins",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.teetime,
                                                    "net_skins",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.teetime,
                                                "net_skins",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _vm._v(" Net "),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "col-4 col-md-3" }, [
                              _c(
                                "div",
                                { staticClass: "form-check float-left" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "form-check-label" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.teetime.gross_skins,
                                            expression: "teetime.gross_skins",
                                          },
                                        ],
                                        staticClass: "form-check-input",
                                        attrs: {
                                          "true-value": 1,
                                          "false-value": 0,
                                          type: "checkbox",
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.teetime.gross_skins
                                          )
                                            ? _vm._i(
                                                _vm.teetime.gross_skins,
                                                null
                                              ) > -1
                                            : _vm._q(
                                                _vm.teetime.gross_skins,
                                                1
                                              ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.teetime.gross_skins,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? 1 : 0
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.teetime,
                                                    "gross_skins",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.teetime,
                                                    "gross_skins",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.teetime,
                                                "gross_skins",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _vm._v(" Gross "),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
              ]),
              _c(
                "b-row",
                { staticClass: "mt-4" },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "b-button",
                        { attrs: { block: "" }, on: { click: _vm.cancel } },
                        [_vm._v("Cancel")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { block: "", variant: "success" },
                          on: {
                            click: function ($event) {
                              return _vm.goToGroupSelect()
                            },
                          },
                        },
                        [_vm._v("Set groups")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.step == 5
        ? _c(
            "div",
            { staticClass: "col-12 col-md-8 mx-auto mt-2 align-middle" },
            [
              _c("b-card", { attrs: { "body-class": "p-0" } }, [
                _c("div", { staticClass: "row mb-4 mt-4 ml-n1" }, [
                  _c("div", { staticClass: "col h4" }, [
                    _c("i", {
                      staticClass: "fa fa-arrow-circle-left back-icon",
                      on: {
                        click: function ($event) {
                          return _vm.backToFormat()
                        },
                      },
                    }),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "text-center font-weight-bold my-4 h3" },
                  [_vm._v(" Set Teebox / Group ")]
                ),
                _c(
                  "div",
                  { staticClass: "px-4" },
                  [
                    _vm._v(
                      " Default: Net is scored off full course handicap for each player"
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v(" Choose to spin off low handicap:"),
                    _c("br"),
                    _c(
                      "b-form-group",
                      [
                        _c("b-form-radio-group", {
                          attrs: { options: _vm.spinOptions, stacked: "" },
                          model: {
                            value: _vm.spinType,
                            callback: function ($$v) {
                              _vm.spinType = $$v
                            },
                            expression: "spinType",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.golfers && _vm.golfers.length > 0
                  ? _c("div", { staticClass: "d-none d-md-flex" }, [
                      _vm.renderList
                        ? _c(
                            "table",
                            { staticClass: "table mb-0 table-bordered" },
                            [
                              _c("thead", {}, [
                                _c("tr", [
                                  _c(
                                    "th",
                                    { staticClass: "text-center align-middle" },
                                    [_vm._v("Info")]
                                  ),
                                  _vm.golfers.length > 4
                                    ? _c(
                                        "th",
                                        {
                                          staticClass:
                                            "text-center align-middle",
                                        },
                                        [_vm._v("Group")]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "th",
                                    { staticClass: "text-center align-middle" },
                                    [_vm._v("Handicap Index")]
                                  ),
                                  _c(
                                    "th",
                                    { staticClass: "text-center align-middle" },
                                    [_vm._v("Course Index")]
                                  ),
                                  _c(
                                    "th",
                                    { staticClass: "text-center align-middle" },
                                    [_vm._v("Teebox")]
                                  ),
                                ]),
                              ]),
                              _c(
                                "tbody",
                                _vm._l(_vm.golfers, function (u, index) {
                                  return _c("tr", { key: index }, [
                                    _c(
                                      "td",
                                      {
                                        staticClass: "text-center align-middle",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(u.firstname) +
                                            " " +
                                            _vm._s(u.lastname) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _vm.golfers.length > 4
                                      ? _c(
                                          "td",
                                          {
                                            staticClass:
                                              "text-center align-middle",
                                          },
                                          [
                                            _c(
                                              "b-form-group",
                                              { attrs: { label: "Group" } },
                                              _vm._l(
                                                Math.ceil(
                                                  _vm.golfers.length / 4
                                                ),
                                                function (g) {
                                                  return _c(
                                                    "b-form-checkbox",
                                                    {
                                                      key: g,
                                                      attrs: {
                                                        value: g,
                                                        checked: [u.tgroup_id],
                                                        "unchecked-value": null,
                                                        disabled:
                                                          !_vm.groupAvailable(
                                                            u.user_id,
                                                            g
                                                          ),
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          return _vm.groupsChanged(
                                                            $event
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value: u.tgroup_id,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            u,
                                                            "tgroup_id",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "u.tgroup_id",
                                                      },
                                                    },
                                                    [_vm._v(_vm._s(g))]
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "text-center align-middle",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "input-group form-inline space-x-4",
                                          },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-info btn-sm input-group-btn mr-2",
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.decreaseHandicap(
                                                      u
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "fas fa-minus",
                                                }),
                                              ]
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: u.handicapindex,
                                                  expression: "u.handicapindex",
                                                },
                                              ],
                                              staticClass:
                                                "form-control input-sm no-spinner mr-2",
                                              attrs: {
                                                type: "number",
                                                maxlength: "2",
                                                step: "0.1",
                                                size: "3",
                                                required: "",
                                                id: "handicap",
                                              },
                                              domProps: {
                                                value: u.handicapindex,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    u,
                                                    "handicapindex",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-info btn-sm input-group-btn",
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.increaseHandicap(
                                                      u
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "fas fa-plus",
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "text-center align-middle",
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(u.coursehandicap) + " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "text-center align-middle",
                                      },
                                      [
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: u.teebox_id,
                                                expression: "u.teebox_id",
                                              },
                                            ],
                                            staticClass:
                                              "custom-select mb-2 mr-sm-2 mb-sm-0",
                                            on: {
                                              change: function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.$set(
                                                  u,
                                                  "teebox_id",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "option",
                                              {
                                                attrs: { disabled: "" },
                                                domProps: { value: null },
                                              },
                                              [_vm._v("-- SELECT ONE --")]
                                            ),
                                            _vm._l(
                                              _vm.teeboxes,
                                              function (teebox) {
                                                return _c(
                                                  "option",
                                                  {
                                                    key: teebox.id,
                                                    domProps: {
                                                      value: teebox.id,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        teebox.description
                                                      ) +
                                                        " (" +
                                                        _vm._s(teebox.slope) +
                                                        "/" +
                                                        _vm._s(teebox.rating) +
                                                        ") "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ]
                                    ),
                                  ])
                                }),
                                0
                              ),
                            ]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm.golfers && _vm.golfers.length > 0
                  ? _c("div", { staticClass: "d-md-none" }, [
                      _vm.renderList
                        ? _c(
                            "table",
                            { staticClass: "table mb-0 table-bordered" },
                            _vm._l(_vm.golfers, function (u, index) {
                              return _c("tr", { key: index }, [
                                _vm.collapsed[u.user_id + ""]
                                  ? _c(
                                      "td",
                                      {
                                        staticClass: "text-center align-middle",
                                        class:
                                          isNaN(u.tgroup_id) ||
                                          +u.tgroup_id === 0
                                            ? "border border-danger"
                                            : "",
                                        on: {
                                          click: function ($event) {
                                            return _vm.collapseUser(u.user_id)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "float-right" },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-caret-up",
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "text-left" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(u.lastname) +
                                                _vm._s(
                                                  u.firstname.substr(0, 1)
                                                ) +
                                                " - " +
                                                _vm._s(
                                                  _vm.getTeebox(
                                                    u.teebox_id,
                                                    false
                                                  )
                                                ) +
                                                " - Hdcp " +
                                                _vm._s(u.coursehandicap) +
                                                " "
                                            ),
                                            _vm.golfers.length > 4
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      isNaN(u.tgroup_id) ||
                                                        +u.tgroup_id === 0
                                                        ? ""
                                                        : "- Group " +
                                                            u.tgroup_id
                                                    )
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "td",
                                      {
                                        staticClass: "text-center align-middle",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "float-right",
                                            on: {
                                              click: function ($event) {
                                                return _vm.collapseUser(
                                                  u.user_id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-caret-down",
                                            }),
                                          ]
                                        ),
                                        _c("div", [
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(u.firstname) +
                                                " " +
                                                _vm._s(u.lastname)
                                            ),
                                          ]),
                                          _c("br"),
                                          _vm._v(" Handicap Index: "),
                                          _c("br"),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "input-group form-inline space-x-4",
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-info btn-sm input-group-btn px-4 mr-2",
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      return _vm.decreaseHandicap(
                                                        u
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "fas fa-minus",
                                                  }),
                                                ]
                                              ),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: u.handicapindex,
                                                    expression:
                                                      "u.handicapindex",
                                                  },
                                                ],
                                                staticClass:
                                                  "form-control input-sm no-spinner w-12 mr-2",
                                                attrs: {
                                                  type: "number",
                                                  maxlength: "2",
                                                  step: "0.1",
                                                  size: "1",
                                                  required: "",
                                                  id: "handicap",
                                                },
                                                domProps: {
                                                  value: u.handicapindex,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      u,
                                                      "handicapindex",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-info btn-sm input-group-btn px-4",
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      return _vm.increaseHandicap(
                                                        u
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "fas fa-plus",
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            " Course Index: " +
                                              _vm._s(u.coursehandicap) +
                                              " "
                                          ),
                                        ]),
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: "Teebox",
                                              "label-align": "left",
                                            },
                                          },
                                          [
                                            _c(
                                              "select",
                                              {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: u.teebox_id,
                                                    expression: "u.teebox_id",
                                                  },
                                                ],
                                                staticClass:
                                                  "custom-select mb-2 mr-sm-2 mb-sm-0",
                                                on: {
                                                  change: function ($event) {
                                                    var $$selectedVal =
                                                      Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function (o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function (o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                    _vm.$set(
                                                      u,
                                                      "teebox_id",
                                                      $event.target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "option",
                                                  {
                                                    attrs: { disabled: "" },
                                                    domProps: { value: null },
                                                  },
                                                  [_vm._v("-- SELECT ONE --")]
                                                ),
                                                _vm._l(
                                                  _vm.teeboxes,
                                                  function (teebox) {
                                                    return _c(
                                                      "option",
                                                      {
                                                        key: teebox.id,
                                                        domProps: {
                                                          value: teebox.id,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            teebox.description
                                                          ) +
                                                            " (" +
                                                            _vm._s(
                                                              teebox.slope
                                                            ) +
                                                            "/" +
                                                            _vm._s(
                                                              teebox.rating
                                                            ) +
                                                            ") "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                              ],
                                              2
                                            ),
                                          ]
                                        ),
                                        _vm.golfers.length > 4
                                          ? _c(
                                              "b-form-group",
                                              {
                                                staticClass: "text-left",
                                                attrs: {
                                                  label: "Group",
                                                  "label-align": "left",
                                                },
                                              },
                                              _vm._l(
                                                Math.ceil(
                                                  _vm.golfers.length / 4
                                                ),
                                                function (g) {
                                                  return _c(
                                                    "b-form-checkbox",
                                                    {
                                                      key: g,
                                                      attrs: {
                                                        value: g,
                                                        checked: [u.tgroup_id],
                                                        "unchecked-value": null,
                                                        disabled:
                                                          !_vm.groupAvailable(
                                                            u.user_id,
                                                            g
                                                          ),
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          return _vm.groupsChanged(
                                                            $event,
                                                            u.user_id
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value: u.tgroup_id,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            u,
                                                            "tgroup_id",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "u.tgroup_id",
                                                      },
                                                    },
                                                    [_vm._v(_vm._s(g))]
                                                  )
                                                }
                                              ),
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                              ])
                            }),
                            0
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "b-row",
                { staticClass: "mt-4" },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "b-button",
                        { attrs: { block: "" }, on: { click: _vm.cancel } },
                        [_vm._v("Cancel")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            block: "",
                            variant: "success",
                            disabled: !_vm.validForm,
                          },
                          on: { click: _vm.submit },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.existingTeetime
                                  ? "Update outing"
                                  : "Start outing"
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.step == 6
        ? _c(
            "div",
            { staticClass: "col-12 col-md-8 mx-auto mt-2 align-middle" },
            [
              _c("b-card", [
                _c("div", { staticClass: "row mb-4 mt-1 ml-n4" }, [
                  _c("div", { staticClass: "col h4" }, [
                    _c("i", {
                      staticClass: "fa fa-arrow-circle-left back-icon",
                      on: {
                        click: function ($event) {
                          return _vm.goToGroupSelect()
                        },
                      },
                    }),
                  ]),
                ]),
                _vm.teetime.contest_id != 0
                  ? _c("div", [
                      _vm._v(" Game type: " + _vm._s(_vm.gameName) + " "),
                    ])
                  : _vm._e(),
                !_vm.postReady
                  ? _c(
                      "div",
                      [
                        _c("div", { staticClass: "d-none d-md-flex" }, [
                          _c(
                            "table",
                            { staticClass: "table mb-0 table-bordered" },
                            [
                              _c("thead", [
                                _c("tr", [
                                  _c(
                                    "th",
                                    { staticClass: "text-center align-middle" },
                                    [_vm._v("Info")]
                                  ),
                                  _c(
                                    "th",
                                    { staticClass: "text-center align-middle" },
                                    [_vm._v("Course Index")]
                                  ),
                                  _c(
                                    "th",
                                    { staticClass: "text-center align-middle" },
                                    [_vm._v("Teebox")]
                                  ),
                                ]),
                              ]),
                              _c(
                                "tbody",
                                _vm._l(_vm.golfers, function (u, index) {
                                  return _c("tr", { key: index }, [
                                    _c(
                                      "td",
                                      {
                                        staticClass: "text-center align-middle",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(u.firstname) +
                                            " " +
                                            _vm._s(u.lastname)
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          " " + _vm._s(u.accesscode) + " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "text-center align-middle",
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(u.coursehandicap) + " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "text-center align-middle",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.getTeebox(u.teebox_id)) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ])
                                }),
                                0
                              ),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "d-flex d-md-none" }, [
                          _c(
                            "table",
                            { staticClass: "table mb-0 table-bordered" },
                            [
                              _c(
                                "tbody",
                                _vm._l(_vm.golfers, function (u, index) {
                                  return _c("tr", { key: index }, [
                                    _c(
                                      "td",
                                      {
                                        staticClass: "text-center align-middle",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(u.firstname) +
                                            " " +
                                            _vm._s(u.lastname) +
                                            ": "
                                        ),
                                        _c("u", [_vm._v(_vm._s(u.accesscode))]),
                                        _c("br"),
                                        _vm._v(
                                          " Course Index: " +
                                            _vm._s(u.coursehandicap)
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.getTeebox(u.teebox_id)) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ])
                                }),
                                0
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "b-button",
                          {
                            staticClass: "mt-4",
                            attrs: { variant: "danger" },
                            on: {
                              click: function ($event) {
                                return _vm.cancelOuting()
                              },
                            },
                          },
                          [_vm._v("Cancel Outing")]
                        ),
                        _c("br"),
                        _c("small", [
                          _vm._v(
                            " * A new outing cannot be created until you post the scores or cancel the current outing "
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.postReady
                  ? _c(
                      "div",
                      [
                        _c("div", { staticClass: "d-none d-md-flex" }, [
                          _c(
                            "table",
                            { staticClass: "table mb-0 table-bordered" },
                            [
                              _c("thead", [
                                _c("tr", [
                                  _c(
                                    "th",
                                    { staticClass: "text-center align-middle" },
                                    [_vm._v("Name")]
                                  ),
                                  _c(
                                    "th",
                                    { staticClass: "text-center align-middle" },
                                    [_vm._v("Gross Score")]
                                  ),
                                  _c(
                                    "th",
                                    { staticClass: "text-center align-middle" },
                                    [_vm._v("Pars Or Better")]
                                  ),
                                ]),
                              ]),
                              _c(
                                "tbody",
                                _vm._l(_vm.scores, function (u, index) {
                                  return _c("tr", { key: index }, [
                                    _c(
                                      "td",
                                      {
                                        staticClass: "text-center align-middle",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              u.name && u.name != ""
                                                ? u.name
                                                : u.first + " " + u.last
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "text-center align-middle",
                                      },
                                      [_vm._v(" " + _vm._s(u.totalgross) + " ")]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "text-center align-middle",
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(u.parorbetter) + " "
                                        ),
                                      ]
                                    ),
                                  ])
                                }),
                                0
                              ),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "d-flex d-md-none" }, [
                          _c(
                            "table",
                            { staticClass: "table mb-0 table-bordered" },
                            [
                              _c(
                                "tbody",
                                _vm._l(_vm.scores, function (u, index) {
                                  return _c("tr", { key: index }, [
                                    _c(
                                      "td",
                                      {
                                        staticClass: "text-center align-middle",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              u.name && u.name != ""
                                                ? u.name
                                                : u.first + " " + u.last
                                            )
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          " Gross Score: " +
                                            _vm._s(u.totalgross)
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          " Pars or Better: " +
                                            _vm._s(u.parorbetter) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ])
                                }),
                                0
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "b-button",
                          {
                            staticClass: "mt-4",
                            attrs: { block: "", variant: "info" },
                            on: {
                              click: function ($event) {
                                return _vm.postScores()
                              },
                            },
                          },
                          [_vm._v("Post scores")]
                        ),
                        _c("small", [
                          _vm._v(
                            " * A new outing cannot be created until you post the scores for the current outing "
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }