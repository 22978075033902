import { render, staticRenderFns } from "./HandicapDetail.html?vue&type=template&id=3577d3c5&scoped=true&external"
import script from "./HandicapDetail.vue?vue&type=script&lang=js"
export * from "./HandicapDetail.vue?vue&type=script&lang=js"
import style0 from "./HandicapDetail.scss?vue&type=style&index=0&id=3577d3c5&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3577d3c5",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/websites/desert-nomads/app.desertnomads.org/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3577d3c5')) {
      api.createRecord('3577d3c5', component.options)
    } else {
      api.reload('3577d3c5', component.options)
    }
    module.hot.accept("./HandicapDetail.html?vue&type=template&id=3577d3c5&scoped=true&external", function () {
      api.rerender('3577d3c5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/handicapDetail/HandicapDetail.vue"
export default component.exports