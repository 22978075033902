var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row mt-4" },
    [
      _c(
        "div",
        { staticClass: "col-12 mx-auto align-middle" },
        [
          _c("PlayersGeneralInfoCard", {
            attrs: { players: _vm.players, totalSpots: _vm.totalSpots },
          }),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass: "text-center",
                  attrs: { cols: "6", offset: "3", xl: "4", "offset-xl": "4" },
                },
                [
                  _vm.isAdmin
                    ? _c(
                        "div",
                        { staticClass: "my-4" },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { block: "" },
                              on: {
                                click: function ($event) {
                                  _vm.addGolfer = true
                                },
                              },
                            },
                            [_vm._v("Add Golfer")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("PlayersTable", {
                attrs: {
                  teeboxes: _vm.teeboxes,
                  players: _vm.players,
                  tournament: _vm.tournament,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: { scrollable: "", "hide-footer": "" },
          model: {
            value: _vm.addGolfer,
            callback: function ($$v) {
              _vm.addGolfer = $$v
            },
            expression: "addGolfer",
          },
        },
        [
          _vm.addGolfer
            ? _c("TourneySignup", {
                attrs: { tId: +_vm.tId, anonymous: "" },
                on: {
                  submitted: function ($event) {
                    return _vm.refresh()
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }