var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.$slots.header || _vm.$slots.body || _vm.$slots.footer,
          expression: "$slots.header || $slots.body || $slots.footer",
        },
      ],
      staticClass: "card mb-1",
    },
    [
      _vm.$slots.header
        ? _c("div", { staticClass: "card-header" }, [_vm._t("header")], 2)
        : _vm._e(),
      _vm.$slots.body
        ? _c("div", { staticClass: "card-body" }, [_vm._t("body")], 2)
        : _vm._e(),
      _vm.$slots.footer
        ? _c("div", { staticClass: "card-footer" }, [_vm._t("footer")], 2)
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }