var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "button",
        {
          staticClass: "btn btn-danger edit-col",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.confirmDeleteRound()
            },
          },
        },
        [_vm._v("Delete")]
      ),
      _c(
        "b-modal",
        {
          attrs: { "ok-title": "Delete", size: "xl", title: "Delete Round" },
          on: { ok: _vm.deleteRound },
          model: {
            value: _vm.openModal,
            callback: function ($$v) {
              _vm.openModal = $$v
            },
            expression: "openModal",
          },
        },
        [
          _c("div", [
            _c("p", [_vm._v("Are you sure you want to delete this round?")]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }