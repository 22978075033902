var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "table",
      { staticClass: "w-100 text-center table-lines" },
      [
        _vm._m(0),
        _vm._l(_vm.scores, function (s) {
          return _c("tr", { key: s.id }, [
            _c(
              "td",
              {
                class: isNaN(s.hole) ? "" : "hole",
                attrs: { colspan: isNaN(s.hole) ? 2 : 1 },
              },
              [_vm._v(" " + _vm._s(s.hole) + " ")]
            ),
            _c(
              "td",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !isNaN(s.hole),
                    expression: "!isNaN(s.hole)",
                  },
                ],
                staticClass: "par",
              },
              [_vm._v(" " + _vm._s(s.par) + " ")]
            ),
            _c("td", [_vm._v(" " + _vm._s(s.grossscore) + " ")]),
            _c("td", { staticClass: "score" }, [
              _vm._v(" " + _vm._s(_vm.toPar(s.grossscore, s.par)) + " "),
            ]),
            _c("td", { staticClass: "score" }, [
              _vm._v(" " + _vm._s(_vm.toPar(s.netscore, s.par)) + " "),
            ]),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "head" }, [
      _c("th", [_vm._v("Hole")]),
      _c("th", [_vm._v("Par")]),
      _c("th", [_vm._v("Gross")]),
      _c("th", [_vm._v("To Par")]),
      _c("th", [_vm._v("Net To Par")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }