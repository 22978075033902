var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mb-2", staticStyle: { width: "100%" } }, [
    _vm.golfers && _vm.golfers.length
      ? _c(
          "div",
          { staticClass: "input-group", staticStyle: { width: "100%" } },
          [
            _vm._m(0),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.sel,
                    expression: "sel",
                  },
                ],
                staticClass: "custom-select",
                attrs: { id: "golferSelector" },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.sel = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    _vm.changeGolfer,
                  ],
                },
              },
              _vm._l(_vm.golfers, function (golfer, index) {
                return _c(
                  "option",
                  { key: index, domProps: { value: golfer.id } },
                  [
                    _vm._v(
                      _vm._s(golfer.lastname) + " , " + _vm._s(golfer.firstname)
                    ),
                  ]
                )
              }),
              0
            ),
            _vm.sel
              ? _c("div", { staticClass: "input-group-append" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.copyUrl.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Share")]
                  ),
                ])
              : _vm._e(),
          ]
        )
      : _vm._e(),
    !_vm.selectedGolfer
      ? _c("div", { staticClass: "mt-2" }, [
          _c("div", { staticClass: "alert alert-primary" }, [
            _vm._v("Please Select a Golfer."),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c(
        "label",
        {
          staticClass: "input-group-text",
          staticStyle: { width: "100%" },
          attrs: { for: "golferSelector" },
        },
        [_vm._v("Golfers")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }