var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card col-12 col-md-5 mx-auto full-height" },
    [
      _c("div", { staticClass: "card-body", staticStyle: { width: "100%" } }, [
        _c(
          "form",
          {
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.handleSubmit()
              },
            },
          },
          [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "username" } }, [
                _vm._v("Enter Username"),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.username,
                    expression: "username",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  id: "username",
                  placeholder: "username",
                  required: "",
                },
                domProps: { value: _vm.username },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.username = $event.target.value
                  },
                },
              }),
            ]),
            _c("vue-recaptcha", {
              attrs: { sitekey: "6LdLahwUAAAAAAn5KfN0KKVBWSv2VwA_voBGLfh9" },
              on: {
                verify: function ($event) {
                  return _vm.getVerify($event)
                },
              },
            }),
            _c("br"),
            _vm._m(0),
          ],
          1
        ),
        _c("br"),
        _vm.errors.length
          ? _c(
              "p",
              { staticClass: "alert alert-danger", attrs: { role: "alert" } },
              [
                _c("b", [_vm._v("Please correct the following error(s):")]),
                _c(
                  "ul",
                  _vm._l(_vm.errors, function (error, index) {
                    return _c("li", { key: index }, [_vm._v(_vm._s(error))])
                  }),
                  0
                ),
              ]
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticStyle: { width: "100%" } }, [
      _c(
        "button",
        { staticClass: "btn btn-primary", attrs: { type: "submit" } },
        [_vm._v("Continue")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }