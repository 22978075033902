import { render, staticRenderFns } from "./ChooseResetType.vue?vue&type=template&id=234d8992"
import script from "./ChooseResetType.vue?vue&type=script&lang=js"
export * from "./ChooseResetType.vue?vue&type=script&lang=js"
import style0 from "./ChooseResetType.vue?vue&type=style&index=0&id=234d8992&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/websites/desert-nomads/app.desertnomads.org/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('234d8992')) {
      api.createRecord('234d8992', component.options)
    } else {
      api.reload('234d8992', component.options)
    }
    module.hot.accept("./ChooseResetType.vue?vue&type=template&id=234d8992", function () {
      api.rerender('234d8992', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/resetPwdOrEmail/components/ChooseResetType.vue"
export default component.exports