var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("i", {
    staticClass: "fas",
    class: {
      "fa-angle-down": _vm.isCollapsed,
      "fa-angle-up": !_vm.isCollapsed,
    },
    on: { click: _vm.onClickCollapse },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }