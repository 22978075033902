var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-4 mb-4" },
    [
      _c("TeetimeForm", {
        attrs: {
          teetime: _vm.teetime,
          isEdit: _vm.isEdit,
          courses: _vm.courses,
          contests: _vm.contests,
          user: _vm.user,
          teeboxes: _vm.teeboxes,
        },
        on: {
          addTeetime: function ($event) {
            return _vm.onAddTeetime($event)
          },
          onSearchTeeboxes: function ($event) {
            return _vm.onGetSearchTeeboxesFilter($event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }