var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "min-height": "100%" } },
    [
      _c("nav-bar"),
      _c(
        "div",
        { staticClass: "container-fluid body" },
        [_vm._t("default")],
        2
      ),
      _c("footer-bar"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }