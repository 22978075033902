var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { width: "100%" } }, [
    _vm.selectors && _vm.selectors.length
      ? _c("div", { staticClass: "input-group flex-nowrap mt-1 mb-2" }, [
          _vm._m(0),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.sel,
                  expression: "sel",
                },
              ],
              staticClass: "custom-select",
              attrs: { id: "dataSelector" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.sel = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.changeSelect,
                ],
              },
            },
            _vm._l(_vm.selectors, function (obj, index) {
              return _c(
                "option",
                { key: index, domProps: { value: obj.key ? obj.key : obj.id } },
                [_vm._v(_vm._s(obj.value))]
              )
            }),
            0
          ),
        ])
      : _vm._e(),
    !_vm.selectedValue
      ? _c("div", { staticClass: "col-12 col-md-6 mt-2 text-center" }, [
          _c("div", { staticClass: "alert alert-primary" }, [
            _c("span", [
              _vm._v(" Please Select a " + _vm._s(_vm.suggestion) + ". "),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c(
        "span",
        { staticClass: "input-group-text", attrs: { for: "dataSelector" } },
        [_vm._v("Options")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }