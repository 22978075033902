var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "label",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.hideLabel,
              expression: "!hideLabel",
            },
          ],
          staticClass: "form-label",
          attrs: { for: "course-select" },
        },
        [
          _vm._t("label", function () {
            return [_vm._v("Course:")]
          }),
        ],
        2
      ),
      _c("vue-typeahead-bootstrap", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.courseId == null,
            expression: "courseId == null",
          },
        ],
        attrs: {
          data: _vm.courses,
          serializer: (s) => s.name,
          inputClass: !_vm.isValid ? "form-control is-invalid" : "form-control",
          inputName: "course-select",
        },
        on: {
          hit: function ($event) {
            return _vm.selectCourse($event)
          },
        },
        model: {
          value: _vm.courseSearch,
          callback: function ($$v) {
            _vm.courseSearch = $$v
          },
          expression: "courseSearch",
        },
      }),
      _vm.courseInfo
        ? _c("div", { staticClass: "input-group" }, [
            _c(
              "span",
              {
                staticClass: "form-control alert-info",
                staticStyle: { overflow: "hidden" },
              },
              [_vm._v(" " + _vm._s(_vm.courseInfo.name) + " ")]
            ),
            _c("div", { staticClass: "input-group-append" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-secondary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.clearCourse()
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-times" })]
              ),
            ]),
          ])
        : _vm._e(),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.courseId,
            expression: "courseId",
          },
        ],
        attrs: { type: "hidden", required: _vm.required },
        domProps: { value: _vm.courseId },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.courseId = $event.target.value
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }