var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row mt-4" }, [
    _c(
      "div",
      { staticClass: "col-12 mx-auto align-middle" },
      [
        _c("CourseForm", {
          attrs: { course: _vm.course },
          on: {
            sendForm: function ($event) {
              return _vm.onGetForm($event)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }