var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrapper align-top" }, [
    _c(
      "i",
      {
        staticClass: "badge badge-primary",
        attrs: {
          "data-toggle": "modal",
          "data-target": `#website${_vm.websiteId}`,
        },
      },
      [_vm.website ? _c("span", { staticClass: "fas fa-globe" }) : _vm._e()]
    ),
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          id: `website${_vm.websiteId}`,
          tabindex: "-1",
          role: "dialog",
          "aria-labelledby": "webiste",
          "aria-hidden": "true",
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog modal-dialog-centered",
            attrs: { role: "document" },
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [
                _c("h5", { staticClass: "modal-title" }, [
                  _vm._v(_vm._s(_vm.title) + " "),
                ]),
                _vm._m(0),
              ]),
              _c("div", { staticClass: "modal-body" }, [
                _vm._v(" Website : "),
                _c("b", [_vm._v(_vm._s(_vm.website))]),
              ]),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button", "data-dismiss": "modal" },
                  },
                  [_vm._v("Close")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button", "data-dismiss": "modal" },
                    on: {
                      click: function ($event) {
                        return _vm.confirm()
                      },
                    },
                  },
                  [_vm._v("Go")]
                ),
              ]),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }