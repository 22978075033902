var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "d-md-none page-container card" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { disabled: _vm.page == 1 || _vm.isLoading },
          on: {
            click: function ($event) {
              return _vm.changePageTo(_vm.page - 1)
            },
          },
        },
        [_c("span", [_vm._v(" PREV ")])]
      ),
      _vm.page
        ? _c("button", { staticClass: "btn btn-outline-primary" }, [
            _vm._v(
              "Page : " +
                _vm._s(_vm.page) +
                " / " +
                _vm._s(_vm.totalPages) +
                " "
            ),
          ])
        : _vm._e(),
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { disabled: !_vm.nextPage || _vm.isLoading },
          on: {
            click: function ($event) {
              return _vm.changePageTo(_vm.nextPage)
            },
          },
        },
        [_c("span", [_vm._v(" NEXT ")])]
      ),
    ]),
    _c(
      "div",
      {
        staticClass: "row p-2 d-none d-md-flex",
        staticStyle: { position: "relative" },
      },
      [
        _c("div", { staticClass: "col-4 text-left" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { disabled: _vm.page == 1 || _vm.isLoading },
              on: {
                click: function ($event) {
                  return _vm.changePageTo(_vm.page - 1)
                },
              },
            },
            [_c("span", [_vm._v(" PREV ")])]
          ),
        ]),
        _c("div", { staticClass: "col-4 text-center" }, [
          _vm.page
            ? _c(
                "button",
                {
                  staticClass: "btn btn-outline-primary page-number",
                  attrs: { disabled: "" },
                },
                [
                  _vm._v(
                    "Page : " +
                      _vm._s(_vm.page) +
                      " / " +
                      _vm._s(_vm.totalPages) +
                      " "
                  ),
                ]
              )
            : _vm._e(),
        ]),
        _c("div", { staticClass: "text-right col-4" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { disabled: !_vm.nextPage || _vm.isLoading },
              on: {
                click: function ($event) {
                  return _vm.changePageTo(_vm.nextPage)
                },
              },
            },
            [_c("span", [_vm._v(" NEXT ")])]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }