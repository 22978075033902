var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-container", [
    _vm.loading
      ? _c(
          "div",
          {
            staticStyle: {
              width: "100%",
              "padding-top": "20vh",
              "text-align": "center",
            },
          },
          [
            _c("b-spinner", {
              staticStyle: { width: "3rem", height: "3rem" },
              attrs: { variant: "success" },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm.errors
      ? _c("div", [
          _vm._v(
            " There was a problem while trying to confirm your email address. Please check to make sure you have the correct link inputted."
          ),
          _c("br"),
          _vm._v(" If the problem persists "),
          _c("a", { attrs: { href: "/contactUs" } }, [_vm._v("Contact Us")]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }