import { render, staticRenderFns } from "./AimWebsiteIcon.vue?vue&type=template&id=765b209e&scoped=true"
import script from "./AimWebsiteIcon.vue?vue&type=script&lang=js"
export * from "./AimWebsiteIcon.vue?vue&type=script&lang=js"
import style0 from "./AimWebsiteIcon.vue?vue&type=style&index=0&id=765b209e&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "765b209e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/websites/desert-nomads/app.desertnomads.org/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('765b209e')) {
      api.createRecord('765b209e', component.options)
    } else {
      api.reload('765b209e', component.options)
    }
    module.hot.accept("./AimWebsiteIcon.vue?vue&type=template&id=765b209e&scoped=true", function () {
      api.rerender('765b209e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Aim/icons/AimWebsiteIcon.vue"
export default component.exports