var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { width: "100%" } },
    [
      _vm.title
        ? _c("div", { staticClass: "card table-title border-bottom-0" }, [
            _c("div", { staticClass: "card-header text-center" }, [
              _c("i", {
                staticClass: "fa fa-arrow-circle-left back-icon",
                on: {
                  click: function ($event) {
                    return _vm.$router.go(-1)
                  },
                },
              }),
              _c("b", [_vm._v(_vm._s(_vm.title))]),
            ]),
          ])
        : _vm._e(),
      _vm.userInfo && _vm.userInfo.results && _vm.teeboxes && _vm.course
        ? _c("div", { staticClass: "card border-0 d-md-flex" }, [
            _c(
              "div",
              { staticClass: "table-responsive table-striped table-bordered" },
              [
                _c("table", { staticClass: "table mb-0" }, [
                  _c("thead", [
                    _c(
                      "tr",
                      [
                        _vm._m(0),
                        _vm._l(_vm.teeboxes, function (teebox) {
                          return _c(
                            "th",
                            { staticClass: "text-center align-middle" },
                            [_c("span", [_vm._v(_vm._s(teebox.description))])]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                  _vm.userInfo.results.length
                    ? _c(
                        "tbody",
                        _vm._l(_vm.userInfo.results, function (user) {
                          return _c(
                            "tr",
                            [
                              _c(
                                "td",
                                { staticClass: "text-center align-middle" },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        user.lastname + " " + user.firstname
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._l(_vm.teeboxes, function (teebox) {
                                return _c(
                                  "td",
                                  { staticClass: "text-center align-middle" },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatNumber")(
                                            _vm.calculateCourseHandicap(
                                              user,
                                              teebox
                                            ),
                                            "0,00"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                )
                              }),
                            ],
                            2
                          )
                        }),
                        0
                      )
                    : _c("tbody", [_vm._m(1)]),
                ]),
              ]
            ),
          ])
        : _vm._e(),
      _vm.userInfo &&
      _vm.userInfo.results &&
      _vm.userInfo.results.length &&
      !_vm.filters.hidepag
        ? _c("Page", {
            attrs: {
              nextPage: +_vm.userInfo.nextPage,
              page: +_vm.userInfo.page,
              totalPages: +_vm.userInfo.totalPages,
            },
            on: {
              updatePage: function ($event) {
                return _vm.onChangePageTo($event)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", { staticClass: "text-center align-middle" }, [
      _c("span", [_vm._v("Player")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-text-center", attrs: { colspan: "8" } }, [
        _c("b", [_vm._v("No Courses")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }