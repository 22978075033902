var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-inline" },
    [
      _c(
        "a",
        {
          attrs: { href: "javascript:void(0)" },
          on: {
            click: function ($event) {
              _vm.showModal = true
            },
          },
        },
        [
          _vm._t("default", function () {
            return [_vm._v("Wolf Info")]
          }),
        ],
        2
      ),
      _c(
        "b-modal",
        {
          attrs: { size: "xl", "hide-footer": "", scrollable: "" },
          model: {
            value: _vm.showModal,
            callback: function ($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal",
          },
        },
        [
          _c("table", { staticClass: "text-center table-lines" }, [
            _c("tr", [
              _c("th", [_vm._v("Player")]),
              _c("th", [_vm._v("Wolf Score")]),
            ]),
            _c("tr", [
              _c("td", [_vm._v(_vm._s(_vm.holeScore.name))]),
              _c("td", [_vm._v(_vm._s(_vm.holeScore.wscore))]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }