import { render, staticRenderFns } from "./CourseRoundsTable.html?vue&type=template&id=667a31cd&scoped=true&external"
import script from "./CourseRoundsTable.vue?vue&type=script&lang=js"
export * from "./CourseRoundsTable.vue?vue&type=script&lang=js"
import style0 from "./CourseRoundsTable.scss?vue&type=style&index=0&id=667a31cd&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "667a31cd",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/websites/desert-nomads/app.desertnomads.org/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('667a31cd')) {
      api.createRecord('667a31cd', component.options)
    } else {
      api.reload('667a31cd', component.options)
    }
    module.hot.accept("./CourseRoundsTable.html?vue&type=template&id=667a31cd&scoped=true&external", function () {
      api.rerender('667a31cd', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/courseRoundsTable/CourseRoundsTable.vue"
export default component.exports