var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-4 mb-4" },
    [
      _c("FeelerForm", {
        attrs: {
          user: _vm.user,
          regions: _vm.regions,
          feeler: _vm.feeler,
          isEdit: true,
        },
        on: {
          sendForm: function ($event) {
            return _vm.onGetForm($event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }