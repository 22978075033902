var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mt-4 mb-4" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 mx-auto align-middle" }, [
        _c("div", { staticClass: "card p-3 h-100" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("h3", { staticClass: "card-title text-center" }, [
              _vm._v(" Thank you for visiting the Desert Nomads Golf Club "),
            ]),
            _c("hr"),
            _c("div", { staticClass: "text-center pt-3 pb-3" }, [
              _c("p", { staticClass: "card-text" }, [
                _vm._v(" Email questions or comments to "),
              ]),
              _c(
                "a",
                { staticClass: "card-link", attrs: { href: _vm.mailTo } },
                [_vm._v(" membership@desertnomads.org")]
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }