var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mt-3 mb-2 border-top footer" }, [
      _c("div", { staticClass: "footer-text" }, [
        _c(
          "a",
          {
            staticStyle: { display: "inline-block" },
            attrs: { href: "http://www.aimitservices.com" },
          },
          [_vm._v("AIM IT Services, LLC")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }